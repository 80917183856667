import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { key: 5 }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 2,
  class: "image-container-hover"
}
const _hoisted_7 = { class: "image-container-hover-text" }

import TUIChatEngine, { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { ref, watchEffect, withDefaults } from '../../../../adapter-vue';
import MessageAbstractText from './message-abstract/message-abstract-text.vue';
import MessageAbstractFile from './message-abstract/message-abstract-file.vue';
import MessageAbstractCustom from './message-abstract/message-abstract-custom.vue';
import MessageAbstractImage from './message-abstract/message-abstract-image.vue';
import MessageAbstractVideo from './message-abstract/message-abstract-video.vue';
import {
  generateSearchResultShowName,
  generateSearchResultAvatar,
  generateSearchResultShowContent,
  generateSearchResultTime,
  enterConversation,
} from '../../utils';
import { messageTypeAbstractMap, searchResultItemDisplayTypeValues, searchMessageTypeValues, IHighlightContent } from '../../type';
import { ISearchResultListItem } from '../../../../interface';
import { isPC } from '../../../../utils/env';
interface IProps {
  listItem: IMessageModel | ISearchResultListItem;
  listItemContent?: Record<string, unknown>;
  type: searchMessageTypeValues;
  displayType: searchResultItemDisplayTypeValues;
  keywordList: string[];
}

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    listItem: { default: () => ({}) as IMessageModel | ISearchResultListItem },
    listItemContent: { default: () => ({}) as Record<string, unknown> },
    type: { default: 'allMessage' },
    displayType: { default: 'info' },
    keywordList: { default: () => ([]) as string[] }
  },
  emits: ['showResultDetail', 'navigateToChatPosition'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;
const TYPES = ref(TUIChatEngine.TYPES);

const avatarForShow = ref<string>('');
const nameForShow = ref<string>('');
const contentForShow = ref<IHighlightContent[]>([]);
const timeForShow = ref<string>('');

const isHovering = ref<boolean>(false);

watchEffect(() => {
  avatarForShow.value = generateSearchResultAvatar(props.listItem);
  nameForShow.value = generateSearchResultShowName(props.listItem, props?.listItemContent);
  contentForShow.value = generateSearchResultShowContent(
    props.listItem,
    props.type,
    props.keywordList as string[],
    props?.displayType === 'info',
  );
  timeForShow.value = (props.listItem as IMessageModel)?.time
    ? generateSearchResultTime((props.listItem as IMessageModel)?.time * 1000)
    : '';
});

const onResultItemClicked = () => {
  if (props.type === 'contact' || props.type === 'group') {
    enterConversation(props.listItem as IMessageModel);
  } else {
    if (props.displayType === 'info' && !(props.listItem as IMessageModel)?.ID) {
      emits('showResultDetail', true, props.type, props.listItem);
    } else {
      navigateToChatPosition();
    }
  }
};

const setHoverStatus = (status: boolean) => {
  isHovering.value = status;
};

const navigateToChatPosition = () => {
  emits('navigateToChatPosition', props.listItem);
};

const getMessageAbstractType = (message: IMessageModel | ISearchResultListItem) => {
  return message?.type
    ? TUITranslateService.t(`TUISearch.${messageTypeAbstractMap[message.type]}`)
    : TUITranslateService.t(`TUISearch.[合并消息]`);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'search-result-list-item',
      !_unref(isPC) && 'search-result-list-item-h5',
      'search-result-list-item-' + _ctx.displayType,
      _unref(isHovering) && 'hover-' + _ctx.displayType,
    ]),
    onClick: onResultItemClicked,
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (setHoverStatus(true))),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (setHoverStatus(false)))
  }, [
    (_ctx.displayType === 'info' || _ctx.displayType === 'bubble')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.displayType])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.displayType + '-left')
          }, [
            _createElementVNode("img", {
              class: _normalizeClass(_ctx.displayType + '-left-avatar'),
              src: _unref(avatarForShow) || '',
              onerror: "this.onerror=null;this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
            }, null, 10, _hoisted_1)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.displayType + '-main'])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.displayType + '-main-name'])
            }, _toDisplayString(_unref(nameForShow)), 3),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.displayType + '-main-content'])
            }, [
              (_ctx.displayType === 'info' || _ctx.listItem.type === _unref(TYPES).MSG_TEXT)
                ? (_openBlock(), _createBlock(MessageAbstractText, {
                    key: 0,
                    content: _unref(contentForShow),
                    highlightType: _ctx.displayType === 'info' ? 'font' : 'background',
                    displayType: _ctx.displayType
                  }, null, 8, ["content", "highlightType", "displayType"]))
                : (_ctx.listItem.type === _unref(TYPES).MSG_FILE)
                  ? (_openBlock(), _createBlock(MessageAbstractFile, {
                      key: 1,
                      contentText: _unref(contentForShow),
                      messageContent: _ctx.listItemContent,
                      displayType: _ctx.displayType
                    }, null, 8, ["contentText", "messageContent", "displayType"]))
                  : (_ctx.listItem.type === _unref(TYPES).MSG_IMAGE)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                    : (_ctx.listItem.type === _unref(TYPES).MSG_VIDEO)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                      : (_ctx.listItem.type === _unref(TYPES).MSG_CUSTOM)
                        ? (_openBlock(), _createBlock(MessageAbstractCustom, {
                            key: 4,
                            contentText: _unref(contentForShow),
                            message: _ctx.listItem,
                            messageContent: _ctx.listItemContent
                          }, null, 8, ["contentText", "message", "messageContent"]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(getMessageAbstractType(_ctx.listItem)), 1))
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.displayType + '-right')
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.displayType + '-right-time')
            }, _toDisplayString(_unref(timeForShow)), 3),
            (_ctx.displayType === 'bubble' && _unref(isHovering))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.displayType + '-right-to'),
                  onClick: _withModifiers(navigateToChatPosition, ["stop"])
                }, _toDisplayString(_unref(TUITranslateService).t("TUISearch.定位到聊天位置")), 3))
              : _createCommentVNode("", true)
          ], 2)
        ], 2))
      : (_ctx.displayType === 'file')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([_ctx.displayType])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.displayType + '-header'])
            }, [
              _createElementVNode("img", {
                class: _normalizeClass(_ctx.displayType + '-header-avatar'),
                src: _unref(avatarForShow),
                onerror: "this.onerror=null;this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
              }, null, 10, _hoisted_5),
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.displayType + '-header-name'])
              }, _toDisplayString(_unref(nameForShow)), 3),
              (_unref(isHovering))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.displayType + '-header-to'),
                    onClick: _withModifiers(navigateToChatPosition, ["stop"])
                  }, _toDisplayString(_unref(TUITranslateService).t("TUISearch.定位到聊天位置")), 3))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.displayType + '-header-time')
              }, _toDisplayString(_unref(timeForShow)), 3)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.displayType + '-main-content'])
            }, [
              _createVNode(MessageAbstractFile, {
                contentText: _unref(contentForShow),
                messageContent: _ctx.listItemContent,
                displayType: "bubble"
              }, null, 8, ["contentText", "messageContent"])
            ], 2)
          ], 2))
        : (_ctx.displayType === 'image')
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass([_ctx.displayType])
            }, [
              _createElementVNode("div", {
                class: "image-container",
                onClick: _withModifiers(navigateToChatPosition, ["stop"])
              }, [
                (_ctx.listItem.type === _unref(TYPES).MSG_IMAGE)
                  ? (_openBlock(), _createBlock(MessageAbstractImage, {
                      key: 0,
                      messageContent: _ctx.listItemContent
                    }, null, 8, ["messageContent"]))
                  : (_ctx.listItem.type === _unref(TYPES).MSG_VIDEO)
                    ? (_openBlock(), _createBlock(MessageAbstractVideo, {
                        key: 1,
                        messageContent: _ctx.listItemContent
                      }, null, 8, ["messageContent"]))
                    : _createCommentVNode("", true),
                (_unref(isHovering))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(TUITranslateService).t("TUISearch.定位到聊天位置")), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2))
          : _createCommentVNode("", true)
  ], 34))
}
}

})