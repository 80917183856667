import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["onClick", "onLongpress", "onContextmenu"]
const _hoisted_3 = { class: "left" }
const _hoisted_4 = {
  key: 1,
  class: "num"
}
const _hoisted_5 = {
  key: 2,
  class: "num-notify"
}
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "content-header" }
const _hoisted_8 = { class: "content-header-label" }
const _hoisted_9 = { class: "name" }
const _hoisted_10 = { class: "middle-box" }
const _hoisted_11 = {
  key: 0,
  class: "middle-box-draft"
}
const _hoisted_12 = {
  key: 1,
  class: "middle-box-at"
}
const _hoisted_13 = { class: "middle-box-content" }
const _hoisted_14 = { class: "content-footer" }
const _hoisted_15 = { class: "time" }

import { ref, onMounted, onUnmounted } from '../../../adapter-vue';
import TUIChatEngine, {
  TUIStore,
  StoreName,
  TUIConversationService,
  TUITranslateService,
  IConversationModel,
} from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal, isIOS, addLongPressListener } from '@tencentcloud/universal-api';
import Icon from '../../common/Icon.vue';
import Avatar from '../../common/Avatar/index.vue';
import ActionsMenu from '../actions-menu/index.vue';
import muteIcon from '../../../assets/icon/mute.svg';
import { isPC, isH5, isUniFrameWork, isMobile } from '../../../utils/env';

interface IUserStatus {
  statusType: number;
  customStatus: string;
}

interface IUserStatusMap {
  [userID: string]: IUserStatus;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  emits: ['handleSwitchConversation', 'getPassingRef'],
  setup(__props, { expose: __expose, emit: __emit }) {

const emits = __emit;
const currentConversation = ref<IConversationModel>();
const currentConversationID = ref<string>();
const currentConversationDomRect = ref<DOMRect>();
const isShowOverlay = ref<boolean>(false);
const conversationList = ref<IConversationModel[]>([]);
const conversationListDomRef = ref<HTMLElement | undefined>();
const conversationListInnerDomRef = ref<HTMLElement | undefined>();
const actionsMenuPosition = ref<{
  top: number;
  left: number | undefined;
  conversationHeight: number | undefined;
}>({
  top: 0,
  left: undefined,
  conversationHeight: undefined,
});
const displayOnlineStatus = ref(false);
const userOnlineStatusMap = ref<IUserStatusMap>();

let lastestOpenActionsMenuTime: number | null = null;

onMounted(() => {
  TUIStore.watch(StoreName.CONV, {
    currentConversationID: onCurrentConversationIDUpdated,
    conversationList: onConversationListUpdated,
    currentConversation: onCurrentConversationUpdated,
  });

  TUIStore.watch(StoreName.USER, {
    displayOnlineStatus: onDisplayOnlineStatusUpdated,
    userStatusList: onUserStatusListUpdated,
  });

  if (!isUniFrameWork && isIOS && !isPC) {
    addLongPressHandler();
  }
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.CONV, {
    currentConversationID: onCurrentConversationIDUpdated,
    conversationList: onConversationListUpdated,
    currentConversation: onCurrentConversationUpdated,
  });

  TUIStore.unwatch(StoreName.USER, {
    displayOnlineStatus: onDisplayOnlineStatusUpdated,
    userStatusList: onUserStatusListUpdated,
  });
});

const isShowUserOnlineStatus = (conversation: IConversationModel): boolean => {
  return (
    displayOnlineStatus.value
    && conversation.type === TUIChatEngine.TYPES.CONV_C2C
  );
};

const showConversationActionMenu = (
  event: Event,
  conversation: IConversationModel,
  index: number,
  isContextMenuEvent?: boolean,
) => {
  if (isContextMenuEvent) {
    event.preventDefault();
    if (isUniFrameWork) {
      return;
    }
  }
  currentConversation.value = conversation;
  lastestOpenActionsMenuTime = Date.now();
  getActionsMenuPosition(event, index);
};

const closeConversationActionMenu = () => {
  // Prevent continuous triggering of overlay tap events
  if (
    lastestOpenActionsMenuTime
    && Date.now() - lastestOpenActionsMenuTime > 300
  ) {
    currentConversation.value = undefined;
    isShowOverlay.value = false;
  }
};

const getActionsMenuPosition = (event: Event, index: number) => {
  if (isUniFrameWork) {
    if (typeof conversationListDomRef.value === 'undefined') {
      emits('getPassingRef', conversationListDomRef);
    }
    const query = TUIGlobal?.createSelectorQuery().in(conversationListDomRef.value);
    query.select(`#convlistitem-${index}`).boundingClientRect((data) => {
      if (data) {
        actionsMenuPosition.value = {
          // The uni-page-head of uni-h5 is not considered a member of the viewport, so the height of the head is manually increased.
          top: data.bottom + (isH5 ? 44 : 0),
          // @ts-expect-error in uniapp event has touches property
          left: event.touches[0].pageX,
          conversationHeight: data.height,
        };
        isShowOverlay.value = true;
      }
    }).exec();
  } else {
    const rect = ((event.currentTarget || event.target) as HTMLElement)?.getBoundingClientRect() || {};
    if (rect) {
      actionsMenuPosition.value = {
        top: rect.bottom,
        left: isPC ? (event as MouseEvent).clientX : undefined,
        conversationHeight: rect.height,
      };
    }
    isShowOverlay.value = true;
  }
};

const enterConversationChat = (conversationID: string) => {
  emits('handleSwitchConversation', conversationID);
  TUIConversationService.switchConversation(conversationID);
};

function addLongPressHandler() {
  if (!conversationListInnerDomRef.value) {
    return;
  }
  addLongPressListener({
    element: conversationListInnerDomRef.value,
    onLongPress: (event, target) => {
      const index = (Array.from(conversationListInnerDomRef.value!.children) as HTMLElement[]).indexOf(target!);
      showConversationActionMenu(event, conversationList.value[index], index);
    },
    options: {
      eventDelegation: {
        subSelector: '.tui-conversation-content',
      },
    },
  });
}

function onCurrentConversationUpdated(conversation: IConversationModel) {
  currentConversation.value = conversation;
}

function onConversationListUpdated(list: IConversationModel[]) {
  console.log(list)
  conversationList.value = list;
}

function onCurrentConversationIDUpdated(id: string) {
  currentConversationID.value = id;
}

function onDisplayOnlineStatusUpdated(status: boolean) {
  displayOnlineStatus.value = status;
}

function onUserStatusListUpdated(list: Map<string, IUserStatus>) {
  if (list.size !== 0) {
    userOnlineStatusMap.value = [...list.entries()].reduce(
      (obj, [key, value]) => {
        obj[key] = value;
        return obj;
      },
      {} as IUserStatusMap,
    );
  }
}
// Expose to the parent component and close actionsMenu when a sliding event is detected
__expose({ closeChildren: closeConversationActionMenu });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "conversationListInnerDomRef",
    ref: conversationListInnerDomRef,
    class: "tui-conversation-list"
  }, [
    (_unref(isShowOverlay))
      ? (_openBlock(), _createBlock(ActionsMenu, {
          key: 0,
          selectedConversation: _unref(currentConversation),
          actionsMenuPosition: _unref(actionsMenuPosition),
          selectedConversationDomRect: _unref(currentConversationDomRect),
          onCloseConversationActionMenu: closeConversationActionMenu
        }, null, 8, ["selectedConversation", "actionsMenuPosition", "selectedConversationDomRect"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conversationList), (conversation, index) => {
      return (_openBlock(), _createElementBlock("div", {
        id: `convlistitem-${index}`,
        key: index,
        class: _normalizeClass([
        'tui-conversation-content',
        _unref(isMobile) && 'tui-conversation-content-h5 disable-select',
      ])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([
          _unref(isPC) && 'isPC',
          'tui-conversation-item',
          _unref(currentConversationID) === conversation.conversationID &&
            'tui-conversation-item-selected',
          conversation.isPinned && 'tui-conversation-item-pinned',
        ]),
          onClick: ($event: any) => (enterConversationChat(conversation.conversationID)),
          onLongpress: ($event: any) => (showConversationActionMenu($event, conversation, index)),
          onContextmenu: ($event: any) => (showConversationActionMenu($event, conversation, index, true))
        }, [
          _createElementVNode("aside", _hoisted_3, [
            _createVNode(Avatar, {
              useSkeletonAnimation: "",
              url: conversation.getAvatar(),
              size: "30px"
            }, null, 8, ["url"]),
            (_unref(userOnlineStatusMap) && isShowUserOnlineStatus(conversation))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([
              'online-status',
              Object.keys(_unref(userOnlineStatusMap)).length > 0 &&
                Object.keys(_unref(userOnlineStatusMap)).includes(
                  conversation.userProfile.userID
                ) &&
                _unref(userOnlineStatusMap)[conversation.userProfile.userID]
                  .statusType === 1
                ? 'online-status-online'
                : 'online-status-offline',
            ])
                }, null, 2))
              : _createCommentVNode("", true),
            (conversation.unreadCount > 0 && !conversation.isMuted)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(conversation.unreadCount > 99 ? "99+" : conversation.unreadCount), 1))
              : _createCommentVNode("", true),
            (conversation.unreadCount > 0 && conversation.isMuted)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, [
                _createElementVNode("p", _hoisted_9, _toDisplayString(conversation.getShowName()), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                (conversation.draftText && conversation.conversationID !== _unref(currentConversationID))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_unref(TUITranslateService).t('TUIChat.[草稿]')), 1))
                  : (
                  conversation.type === 'GROUP' &&
                    conversation.groupAtInfoList &&
                    conversation.groupAtInfoList.length > 0
                )
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(conversation.getGroupAtInfo()), 1))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_13, _toDisplayString(conversation.getLastMessage("text")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("span", _hoisted_15, _toDisplayString(conversation.getLastMessage("time")), 1),
              (conversation.isMuted)
                ? (_openBlock(), _createBlock(Icon, {
                    key: 0,
                    file: _unref(muteIcon)
                  }, null, 8, ["file"]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 42, _hoisted_2)
      ], 10, _hoisted_1))
    }), 128))
  ], 512))
}
}

})