import request from '@/utils/request';

const urlParams = new URLSearchParams(window.location.search); // 获取查询字符串
const head = {
    aid: 'WEB',
    de: urlParams.get('de'),
    userId: urlParams.get('userId'),
    chkCode: urlParams.get('chkCode')
}

// im获取客服sgin
export const getQuerySignApi = (data: any = {}) => {
    return request({
        url: '/user-server/imClient/querySign',
        method: 'POST',
        data: {
            head,
            con: data
        },
    });
};

// 获取用户列表
export const getCustomerCustomersApi = (data: any) => {
    return request({
        url: '/customer-crm-server/customer/customers',
        method: 'POST',
        data: {
            head,
            con: data
        },
    });
};

// im获取用户信息
export const getQueryCustomerImInfoApi = (data: any) => {
    return request({
        url: '/user-server/imClient/queryCustomerImInfo',
        method: 'POST',
        data: {
            head,
            con: data
        },
    });
};

// im获取聊天记录
export const getQueryC2cMsgApi = (data: any) => {
    return request({
        url: '/user-server/imClient/queryC2cMsg',
        method: 'POST',
        data: {
            head,
            con: data
        },
    });
};
