import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tui-search-input-left" }

import { ref, watch, onMounted } from '../../../adapter-vue';
import { TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import TUICore, { ExtensionInfo, TUIConstants } from '@tencentcloud/tui-core';
import { outsideClick } from '@tencentcloud/universal-api';
import Icon from '../../common/Icon.vue';
import searchIcon from '../../../assets/icon/search.svg';
import searchMoreSVG from '../../../assets/icon/search-more.svg';
import { isPC, isUniFrameWork } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  searchType: {
    type: String,
    default: 'global', // "global" / "conversation"
    validator(value: string) {
      return ['global', 'conversation'].includes(value);
    },
  },
},
  setup(__props) {

const props = __props;

const searchMoreRef = ref<HTMLElement | null>();
const isListShow = ref<boolean>(false);
const toggleMore = () => {
  isListShow.value = !isListShow.value;
  if (!isUniFrameWork && isListShow.value) {
    outsideClick.listen({
      domRefs: searchMoreRef.value,
      handler: closeSearchMore,
    });
  }
};
const extensionList = ref<ExtensionInfo[]>([]);

const handleMenu = (item: ExtensionInfo) => {
  const { listener = { onClicked: () => { } } } = item;
  listener?.onClicked?.(item);
  toggleMore();
};

const closeSearchMore = () => {
  isListShow.value = false;
};

onMounted(() => {
  // extensions
  extensionList.value = [
    ...TUICore.getExtensionList(TUIConstants.TUISearch.EXTENSION.SEARCH_MORE.EXT_ID),
  ];
  // hide conversation header
  TUICore.callService({
    serviceName: TUIConstants.TUIConversation.SERVICE.NAME,
    method: TUIConstants.TUIConversation.SERVICE.METHOD.HIDE_CONVERSATION_HEADER,
    params: {},
  });
});

watch(
  () => isListShow.value,
  () => {
    if (isListShow.value) {
      TUIStore.update(StoreName.SEARCH, 'currentSearchingStatus', {
        isSearching: false,
        searchType: props.searchType,
      });
    }
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "searchMoreRef",
    ref: searchMoreRef,
    class: _normalizeClass(['tui-search-more', !_unref(isPC) && 'tui-search-more-h5'])
  }, [
    _createElementVNode("div", {
      class: "tui-search-input",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (handleMenu(_unref(extensionList)[0])))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(Icon, {
          class: "icon",
          file: _unref(searchIcon),
          width: "14px",
          height: "14px"
        }, null, 8, ["file"])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "tui-search-input-main" }, "搜索", -1))
    ])
  ], 2))
}
}

})