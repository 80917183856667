import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "message-bubble-content" }
const _hoisted_2 = {
  key: 0,
  class: "message-body-nick-name"
}
const _hoisted_3 = { class: "content-main" }
const _hoisted_4 = {
  key: 0,
  class: "content-has-risk-tips"
}
const _hoisted_5 = {
  key: 0,
  class: "audio-unplay-mark"
}
const _hoisted_6 = { class: "message-bubble-extra-content" }

import { computed, toRefs } from '../../../../adapter-vue';
import TUIChatEngine, { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import Icon from '../../../common/Icon.vue';
import ReadStatus from './read-status/index.vue';
import MessageQuote from './message-quote/index.vue';
import Avatar from '../../../common/Avatar/index.vue';
import MessageTranslate from './message-translate/index.vue';
import MessageConvert from './message-convert/index.vue';
import RadioSelect from '../../../common/RadioSelect/index.vue';
import loadingIcon from '../../../../assets/icon/loading.png';
import { shallowCopyMessage } from '../../utils/utils';
import { isPC } from '../../../../utils/env';

interface IProps {
  messageItem: IMessageModel;
  content?: any;
  classNameList?: string[];
  blinkMessageIDList?: string[];
  isMultipleSelectMode?: boolean;
  isAudioPlayed?: boolean | undefined;
  multipleSelectedMessageIDList?: string[];
  userProfile: object;
}

interface IEmits {
  (e: 'resendMessage'): void;
  (e: 'blinkMessage', messageID: string): void;
  (e: 'setReadReceiptPanelVisible', visible: boolean, message?: IMessageModel): void;
  (e: 'changeSelectMessageIDList', options: { type: 'add' | 'remove' | 'clearAll'; messageID: string }): void;
  // Only for uniapp
  (e: 'scrollTo', scrollHeight: number): void;
}

const riskImageReplaceUrl = 'https://web.sdk.qcloud.com/component/TUIKit/assets/has_risk_default.png';

export default /*@__PURE__*/_defineComponent({
  __name: 'message-bubble',
  props: {
    messageItem: { default: () => ({} as IMessageModel) },
    content: { default: () => ({}) },
    classNameList: { default: () => [] },
    blinkMessageIDList: { default: () => [] },
    isMultipleSelectMode: { type: Boolean, default: false },
    isAudioPlayed: { type: Boolean, default: false },
    multipleSelectedMessageIDList: { default: () => [] },
    userProfile: { default: () => [] }
  },
  emits: ["resendMessage", "blinkMessage", "setReadReceiptPanelVisible", "changeSelectMessageIDList", "scrollTo"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;

const props = __props;

const TYPES = TUIChatEngine.TYPES;
const needLoadingIconMessageType = [
  TYPES.MSG_LOCATION,
  TYPES.MSG_TEXT,
  TYPES.MSG_CUSTOM,
  TYPES.MSG_MERGER,
  TYPES.MSG_FACE,
];

const { blinkMessageIDList, messageItem: message } = toRefs(props);

const isMultipleSelected = computed<boolean>(() => {
  return props.multipleSelectedMessageIDList.includes(message.value.ID);
});

const isDisplayUnplayMark = computed<boolean>(() => {
  return message.value.flow === 'in'
    && message.value.status === 'success'
    && message.value.type === TYPES.MSG_AUDIO
    && !props.isAudioPlayed;
});

const containerClassNameList = computed(() => {
  return [
    'message-bubble',
    isMultipleSelected.value ? 'multiple-selected' : '',
    ...props.classNameList,
  ];
});

// When an emoji is deleted, the `reactionList` will update the corresponding emoji's `totalUserCount`.
const hasEmojiReaction = computed(() => {
  return message.value?.reactionList?.some((item: any) => item.totalUserCount !== 0);
});

const isNoPadding = computed(() => {
  return !hasEmojiReaction.value && [TYPES.MSG_IMAGE, TYPES.MSG_VIDEO, TYPES.MSG_MERGER].includes(message.value.type);
});

const riskContentText = computed<string>(() => {
  let content = TUITranslateService.t('TUIChat.涉及敏感内容') + ', ';
  if (message.value.flow === 'out') {
    content += TUITranslateService.t('TUIChat.发送失败');
  } else {
    content += TUITranslateService.t(
      message.value.type === TYPES.MSG_AUDIO ? 'TUIChat.无法收听' : 'TUIChat.无法查看',
    );
  }
  return content;
});

const isBlink = computed(() => {
  if (message.value?.ID) {
    return blinkMessageIDList?.value?.includes(message.value.ID);
  }
  return false;
});

function toggleMultipleSelect(isSelected: boolean) {
  emits('changeSelectMessageIDList', {
    type: isSelected ? 'add' : 'remove',
    messageID: message.value.ID,
  });
}

function resendMessage() {
  if (!message.value?.hasRiskContent) {
    emits('resendMessage');
  }
}

function blinkMessage(messageID: string) {
  emits('blinkMessage', messageID);
}

function scrollTo(scrollHeight: number) {
  emits('scrollTo', scrollHeight);
}

function openReadUserPanel() {
  emits('setReadReceiptPanelVisible', true, message.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(containerClassNameList))
  }, [
    (props.isMultipleSelectMode)
      ? (_openBlock(), _createBlock(RadioSelect, {
          key: 0,
          class: "multiple-select-radio",
          isSelected: _unref(isMultipleSelected),
          onOnChange: toggleMultipleSelect
        }, null, 8, ["isSelected"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({
        'control-reverse': _unref(message).flow === 'out'
      })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["message-bubble-main-content", [_unref(message).flow === 'in' ? '' : 'reverse']])
        }, [
          _createVNode(Avatar, {
            useSkeletonAnimation: "",
            url: (_unref(message).flow === 'in' ? (props.userProfile.avatar || '') : 'https://mdstar.oss-accelerate.aliyuncs.com/v3/message/service-buddha.png') || ''
          }, null, 8, ["url"]),
          _createElementVNode("main", {
            class: "message-body",
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }, [
            (_unref(message).flow === 'in' && _unref(message).conversationType === 'GROUP')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(props.content.showName), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(['message-body-main', _unref(message).flow === 'out' && 'message-body-main-reverse'])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([
                  'blink',
                  'message-body-content',
                  _unref(message).flow === 'out' ? 'content-out' : 'content-in',
                  _unref(message).hasRiskContent && 'content-has-risk',
                  _unref(isNoPadding) ? 'content-no-padding' : '',
                  _unref(isNoPadding) && _unref(isBlink) ? 'blink-shadow' : '',
                  !_unref(isNoPadding) && _unref(isBlink) ? 'blink-content' : '',
                ])
              }, [
                _createElementVNode("div", _hoisted_3, [
                  (
                      (_unref(message).type === _unref(TYPES).MSG_IMAGE || _unref(message).type === _unref(TYPES).MSG_VIDEO) &&
                        _unref(message).hasRiskContent
                    )
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: _normalizeClass(['message-risk-replace', !_unref(isPC) && 'message-risk-replace-h5']),
                        src: riskImageReplaceUrl
                      }, null, 2))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _renderSlot(_ctx.$slots, "messageElement"),
                        _renderSlot(_ctx.$slots, "TUIEmojiPlugin")
                      ], 64))
                ]),
                (_unref(message).hasRiskContent)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(riskContentText)), 1))
                  : _createCommentVNode("", true)
              ], 2),
              (_unref(isDisplayUnplayMark))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                : _createCommentVNode("", true),
              (_unref(message).status === 'fail' || _unref(message).hasRiskContent)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "message-label fail",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (resendMessage()))
                  }, " ! "))
                : _createCommentVNode("", true),
              (!props.content.MsgType)
                ? (_openBlock(), _createBlock(ReadStatus, {
                    key: 2,
                    class: "message-label align-self-bottom",
                    message: _unref(shallowCopyMessage)(_unref(message)),
                    onOpenReadUserPanel: openReadUserPanel
                  }, null, 8, ["message"]))
                : _createCommentVNode("", true)
            ], 2)
          ])
        ], 2),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(MessageTranslate, {
            class: _normalizeClass(_unref(message).flow === 'out' ? 'reverse' : 'flex-row'),
            message: _unref(message)
          }, null, 8, ["class", "message"]),
          _createVNode(MessageConvert, {
            class: _normalizeClass(_unref(message).flow === 'out' ? 'reverse' : 'flex-row'),
            message: _unref(message)
          }, null, 8, ["class", "message"]),
          _createVNode(MessageQuote, {
            class: _normalizeClass(_unref(message).flow === 'out' ? 'reverse' : 'flex-row'),
            message: _unref(message),
            onBlinkMessage: blinkMessage,
            onScrollTo: scrollTo
          }, null, 8, ["class", "message"])
        ])
      ])
    ], 2)
  ], 2))
}
}

})