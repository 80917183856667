import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { computed } from '../../adapter-vue';
import { TUIStore } from '@tencentcloud/chat-uikit-engine';
import TUIChatEngine, { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import {
  isCustomerServicePluginMessage,
  isCustomServiceMessageInvisible,
} from './message-customer/index';
import { isCallMessage, isRoomCardMessage } from './index';
import MessagePluginLayout from './message-plugin-layout.vue';
import MessageCallGroup from './message-call/message-call-group.vue';
import MessageCallC2C from './message-call/message-call-c2c.vue';
import MessageCustomerService from './message-customer/message-customer-service.vue';
// If TUIRoom is not integrated, please introduce the following path
import MessageRoom from './message-room/message-room-default.vue';
// After integrating TUIRoom, please comment the above path and open the following path to import
// import MessageRoom from './message-room/message-room.vue';

interface IProps {
  message: IMessageModel;
  blinkMessageIDList?: string[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'message-plugin',
  props: {
    message: { default: () => ({} as IMessageModel) },
    blinkMessageIDList: { default: () => [] as string[] }
  },
  emits: ['resendMessage', 'handleToggleMessageItem', 'handleH5LongPress'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;
const messageModel = computed(() => TUIStore.getMessageModel(props.message.ID));
const messageSignalingInfo = computed(() => messageModel?.value?.getSignalingInfo());
const messageCustomContent = computed(() => messageModel?.value?.getMessageContent());

const pluginMessageType = computed<{ pluginType: string; showStyle: string }>(() => {
  let typeObj = { pluginType: '', showStyle: '' };
  if (isCallMessage(messageModel.value)) {
    typeObj = {
      pluginType: 'call',
      showStyle:
        messageModel.value?.conversationType === TUIChatEngine.TYPES.CONV_GROUP ? 'tip' : 'bubble',
    };
  } else if (isRoomCardMessage(messageModel.value)) {
    typeObj = {
      pluginType: 'room',
      showStyle: 'bubble',
    };
  } else if (isCustomerServicePluginMessage(messageModel.value)) {
    typeObj = {
      pluginType: 'customer',
      showStyle: isCustomServiceMessageInvisible(messageModel.value) ? '' : 'bubble',
    };
  }
  return typeObj;
});

// The following are for external interaction such as messageTool, no special processing is required, please do not touch
const resendMessage = (message: IMessageModel) => {
  emits('resendMessage', message);
};
const handleToggleMessageItem = (e: any, message: IMessageModel, isLongpress = false) => {
  emits('handleToggleMessageItem', e, message, isLongpress);
};
const handleH5LongPress = (e: any, message: IMessageModel, type: string) => {
  emits('handleH5LongPress', e, message, type);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MessagePluginLayout, {
    message: props.message,
    showStyle: _unref(pluginMessageType).showStyle,
    bubbleClassNameList: [_unref(pluginMessageType).pluginType === 'room' ? 'message-bubble-room':''],
    onResendMessage: resendMessage,
    onHandleToggleMessageItem: handleToggleMessageItem,
    onHandleH5LongPress: handleH5LongPress
  }, {
    messageTip: _withCtx(() => [
      _createVNode(MessageCallGroup, {
        message: props.message,
        signalingInfo: _unref(messageSignalingInfo),
        customContent: _unref(messageCustomContent),
        blinkMessageIDList: props.blinkMessageIDList
      }, null, 8, ["message", "signalingInfo", "customContent", "blinkMessageIDList"])
    ]),
    messageBubble: _withCtx(() => [
      (_unref(pluginMessageType).pluginType === 'call')
        ? (_openBlock(), _createBlock(MessageCallC2C, {
            key: 0,
            message: props.message,
            signalingInfo: _unref(messageSignalingInfo),
            customContent: _unref(messageCustomContent)
          }, null, 8, ["message", "signalingInfo", "customContent"]))
        : _createCommentVNode("", true),
      (_unref(pluginMessageType).pluginType === 'customer')
        ? (_openBlock(), _createBlock(MessageCustomerService, {
            key: 1,
            message: props.message
          }, null, 8, ["message"]))
        : _createCommentVNode("", true),
      (_unref(pluginMessageType).pluginType === 'room')
        ? (_openBlock(), _createBlock(MessageRoom, {
            key: 2,
            message: props.message
          }, null, 8, ["message"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["message", "showStyle", "bubbleClassNameList"]))
}
}

})