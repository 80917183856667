import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

import { TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import { ref } from '../../adapter-vue';
import ConversationList from './conversation-list/index.vue';
import ConversationHeader from './conversation-header/index.vue';
import ConversationNetwork from './conversation-network/index.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  emits: ['handleSwitchConversation'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const totalUnreadCount = ref(0);
const headerRef = ref<typeof ConversationHeader>();
const isShowConversationHeader = ref<boolean>(true);

TUIStore.watch(StoreName.CONV, {
  totalUnreadCount: (count: number) => {
    totalUnreadCount.value = count;
  },
});

TUIStore.watch(StoreName.CUSTOM, {
  isShowConversationHeader: (showStatus: boolean) => {
    isShowConversationHeader.value = showStatus !== false;
  },
});

const handleSwitchConversation = (conversationID: string) => {
  emits('handleSwitchConversation', conversationID);
};

const handleClickConv = () => {
  headerRef?.value?.closeChildren();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "tui-conversation",
    onClick: handleClickConv
  }, [
    (_unref(isShowConversationHeader))
      ? (_openBlock(), _createBlock(ConversationHeader, {
          key: 0,
          ref_key: "headerRef",
          ref: headerRef
        }, null, 512))
      : _createCommentVNode("", true),
    _createVNode(ConversationNetwork),
    _createVNode(ConversationList, { onHandleSwitchConversation: handleSwitchConversation })
  ]))
}
}

})