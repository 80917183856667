import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "TUIKit-main-container" }
const _hoisted_2 = {
  key: 0,
  class: "TUIKit-main"
}
const _hoisted_3 = {
  key: 0,
  class: "TUIKit-main-aside"
}
const _hoisted_4 = {
  key: 1,
  class: "TUIKit-main-main"
}
const _hoisted_5 = {
  key: 1,
  class: "TUIKit-main"
}

import { ref, onMounted, framework } from './adapter-vue';
import { TUILogin } from '@tencentcloud/tui-core';
import { TUIStore, StoreName, TUIConversationService, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { TUISearch, TUIConversation, TUIChat, TUIContact, TUIGroup } from './components';
import { isH5 } from './utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  // When deploying a production environment, you do not need to pass in SDKAppID, userID, and userSig through props.
  // eslint-disable-next-line vue/prop-name-casing
  SDKAppID: {
    type: Number,
    default: 0,
    required: false,
  },
  userID: {
    type: String,
    default: '',
    required: false,
  },
  userSig: {
    type: String,
    default: '',
    required: false,
  },
  conversationID: {
    type: String,
    default: '',
    required: false, // When integrating Chat independently, you need to pass the conversationID
  },
},
  setup(__props) {

const currentConversationID = ref<string>('');
const currentNavbar = ref<string>('message');
const currentLanguage = ref<string>('zh');
const navbarList = [
  {
    id: 'message',
    label: '消息',
  },
  {
    id: 'contact',
    label: '通讯录',
  },
];

const props = __props;

onMounted(() => {
  TUIStore.watch(StoreName.CONV, {
    currentConversationID: (id: string) => {
      currentConversationID.value = id;
    },
  });

  // Default login logic
  login();

  // init language by SDKAppID
  initLanguage();

  // Modify CallKit style in H5 environment
  modifyCallKitStyle();
});

function login() {
  const { SDKAppID, userID, userSig, conversationID } = props;
  if (SDKAppID && userID && userSig) {
    TUILogin.login({
      SDKAppID,
      userID,
      userSig,
      useUploadPlugin: true,
      framework,
    }).then(() => {
      // Execute the following code when integrating Chat independently
      if (conversationID.startsWith('C2C') || conversationID.startsWith('GROUP')) {
        TUIConversationService.switchConversation(conversationID);
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).catch((error) => { });
  }
}

function isIntlSDKAppID(SDKAppID: number) {
  if ((SDKAppID === 0)
    || (SDKAppID >= 20000000 && SDKAppID < 90000000)
    || (SDKAppID >= 1720000000 && SDKAppID < 1790000000)) {
    return true;
  }
  return false;
}

function initLanguage() {
  currentLanguage.value = isIntlSDKAppID(props.SDKAppID) ? 'en' : 'zh';
  TUITranslateService.changeLanguage(currentLanguage.value);
}

function modifyCallKitStyle() {
  if (isH5) {
    const bodyStyle = document.getElementsByTagName('body')[0].style;
    bodyStyle.setProperty('--callLeft', '0');
    bodyStyle.setProperty('--callTop', '0');
    bodyStyle.setProperty('--callWidth', '100%');
    bodyStyle.setProperty('--callHeight', '100%');
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", { key: _unref(currentLanguage) }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("div", {
        class: _normalizeClass(['TUIKit', _unref(isH5) && 'TUIKit-h5'])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_unref(currentNavbar) === 'message')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (!(_unref(isH5) && _unref(currentConversationID)))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_unref(TUISearch), { searchType: "global" }),
                      _createVNode(_unref(TUIConversation))
                    ]))
                  : _createCommentVNode("", true),
                (!_unref(isH5) || _unref(currentConversationID))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_unref(TUIChat), { userID: __props.userID }, {
                        default: _withCtx(() => [
                          _createElementVNode("h1", null, _toDisplayString(_unref(TUITranslateService).t('欢迎使用腾讯云即时通信IM')), 1)
                        ]),
                        _: 1
                      }, 8, ["userID"]),
                      _createVNode(_unref(TUIGroup), {
                        class: _normalizeClass(_unref(isH5) ? 'chat-popup' : 'chat-aside')
                      }, null, 8, ["class"]),
                      _createVNode(_unref(TUISearch), {
                        class: _normalizeClass(_unref(isH5) ? 'chat-popup' : 'chat-aside'),
                        searchType: "conversation"
                      }, null, 8, ["class"])
                    ]))
                  : _createCommentVNode("", true),
                (_unref(isH5) && !_unref(currentConversationID))
                  ? (_openBlock(), _createBlock(_unref(TUIGroup), {
                      key: 2,
                      class: "chat-popup"
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_unref(TUIContact), { displayType: "selectFriend" })
              ]))
            : (_unref(currentNavbar) === 'contact')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_unref(TUIContact), {
                    displayType: "contactList",
                    onSwitchConversation: _cache[0] || (_cache[0] = ($event: any) => (currentNavbar.value = 'message'))
                  })
                ]))
              : _createCommentVNode("", true)
        ])
      ], 2)
    ])
  ]))
}
}

})