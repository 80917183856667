import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

import {
  ref,
  onMounted,
  computed,
  withDefaults,
  onUnmounted,
} from '../../adapter-vue';
import { TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal, outsideClick } from '@tencentcloud/universal-api';
import SearchInput from './search-input/index.vue';
import SearchContainer from './search-container/index.vue';
import SearchResult from './search-result/index.vue';
import SearchMore from './search-more/index.vue';
import { searchMessageTypeDefault } from './search-type-list';
import { searchMessageTimeDefault } from './search-time-list';
import { isPC, isUniFrameWork } from '../../utils/env';
import { ISearchingStatus, SEARCH_TYPE } from './type';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    searchType: { default: () => {
      return isUniFrameWork ? 'conversation' : 'global';
    } }
  },
  setup(__props: any) {

const props = __props;
const globalSearchRef = ref<HTMLElement | null>();
const currentConversationID = ref<string>('');
const searchingStatus = ref<boolean>(false);
// Whether to display the search in the chat
const isShowInConversationSearch = ref<boolean>(isUniFrameWork);
// Whether to search in full screen - Search in full screen when the mobile terminal is searching
const isFullScreen = computed(
  () =>
    !isPC
    && ((props.searchType === 'global' && searchingStatus.value)
    || (props.searchType === 'conversation' && isShowInConversationSearch.value)),
);

const initSearchValue = (searchType: SEARCH_TYPE) => {
  TUIStore.update(StoreName.SEARCH, 'currentSearchInputValue', {
    value: '',
    searchType: searchType,
  });
  TUIStore.update(StoreName.SEARCH, 'currentSearchMessageType', {
    value: searchMessageTypeDefault[searchType],
    searchType: searchType,
  });
  TUIStore.update(StoreName.SEARCH, 'currentSearchMessageTime', {
    value: searchMessageTimeDefault,
    searchType: searchType,
  });
};

function onCurrentConversationIDUpdate(conversationID: string) {
  if (!isUniFrameWork && currentConversationID.value !== conversationID) {
    // PC side single page switch session, close search
    closeInConversationSearch();
  }
  currentConversationID.value = conversationID;
}

function onCurrentSearchingStatusChange(value: ISearchingStatus) {
  if (value?.searchType === props.searchType) {
    searchingStatus.value = value?.isSearching;
    // global search ui bind on click outside close
    if (value?.searchType === 'global' && globalSearchRef.value) {
      if (isPC && value.isSearching) {
        outsideClick.listen({
          domRefs: globalSearchRef.value,
          handler: closeGlobalSearch,
        });
      }
    }
    if (value?.searchType === 'global' && isUniFrameWork) {
      // hide tab bar in uni-app when global searching
      value.isSearching ? TUIGlobal?.hideTabBar()?.catch(() => { /* ignore */ }) : TUIGlobal?.showTabBar()?.catch(() => { /* ignore */ });
    }
  }
}

function onIsShowInConversationSearchChange(value: boolean) {
  isShowInConversationSearch.value = value ? true : false;
  isShowInConversationSearch.value && initSearchValue(props.searchType);
}

onMounted(() => {
  // init with default value
  ['global', 'conversation'].forEach((type: string) => {
    initSearchValue(type as SEARCH_TYPE);
  });
  // watch store change
  TUIStore.watch(StoreName.CONV, {
    currentConversationID: onCurrentConversationIDUpdate,
  });
  TUIStore.watch(StoreName.SEARCH, {
    currentSearchingStatus: onCurrentSearchingStatusChange,
    isShowInConversationSearch: onIsShowInConversationSearchChange,
  });
});

onUnmounted(() => {
  // unwatch store change
  TUIStore.unwatch(StoreName.CONV, {
    currentConversationID: onCurrentConversationIDUpdate,
  });
  TUIStore.unwatch(StoreName.SEARCH, {
    currentSearchingStatus: onCurrentSearchingStatusChange,
    isShowInConversationSearch: onIsShowInConversationSearchChange,
  });
});

function closeGlobalSearch() {
  TUIStore.update(StoreName.SEARCH, 'currentSearchingStatus', {
    isSearching: false,
    searchType: props.searchType,
  });
}

function closeInConversationSearch() {
  TUIStore.update(StoreName.SEARCH, 'isShowInConversationSearch', false);
}

return (_ctx: any,_cache: any) => {
  return (
      _ctx.searchType === 'global' ||
        ((_ctx.searchType === 'conversation' || (!_ctx.searchType && _unref(isUniFrameWork))) &&
          _unref(isShowInConversationSearch))
    )
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
      'tui-search',
      !_unref(isPC) && 'tui-search-h5',
      `tui-search-main-${_ctx.searchType ? _ctx.searchType : 'conversation'}`,
      _unref(isFullScreen) && 'tui-search-h5-full-screen',
    ])
      }, [
        (_ctx.searchType === 'global')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref_key: "globalSearchRef",
              ref: globalSearchRef,
              class: _normalizeClass(['tui-search-global', !_unref(isPC) && 'tui-search-h5-global'])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([
          'tui-search-global-header',
          !_unref(isPC) && 'tui-search-h5-global-header',
        ])
              }, [
                (_unref(isPC) || !_unref(searchingStatus))
                  ? (_openBlock(), _createBlock(SearchMore, {
                      key: 0,
                      class: "search-more",
                      searchType: _ctx.searchType
                    }, null, 8, ["searchType"]))
                  : _createCommentVNode("", true)
              ], 2),
              (_unref(searchingStatus))
                ? (_openBlock(), _createBlock(SearchContainer, {
                    key: 0,
                    class: "search-container",
                    popupPosition: "bottom",
                    searchType: _ctx.searchType
                  }, {
                    result: _withCtx(() => [
                      _createVNode(SearchResult, {
                        class: "search-result",
                        searchType: _ctx.searchType
                      }, null, 8, ["searchType"])
                    ]),
                    _: 1
                  }, 8, ["searchType"]))
                : _createCommentVNode("", true)
            ], 2))
          : (
        (_ctx.searchType === 'conversation' && _unref(isShowInConversationSearch)) ||
          _unref(isUniFrameWork)
      )
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([
        'tui-search-conversation',
        !_unref(isPC) && 'tui-search-h5-conversation',
      ])
              }, [
                _createVNode(SearchContainer, {
                  class: "search-container",
                  popupPosition: "aside",
                  searchType: _ctx.searchType ? _ctx.searchType : 'conversation',
                  onCloseInConversationSearch: closeInConversationSearch
                }, {
                  input: _withCtx(() => [
                    _createVNode(SearchInput, {
                      searchType: _ctx.searchType ? _ctx.searchType : 'conversation'
                    }, null, 8, ["searchType"])
                  ]),
                  result: _withCtx(() => [
                    _createVNode(SearchResult, {
                      class: "search-result",
                      searchType: _ctx.searchType ? _ctx.searchType : 'conversation'
                    }, null, 8, ["searchType"])
                  ]),
                  _: 1
                }, 8, ["searchType"])
              ], 2))
            : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}
}

})