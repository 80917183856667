import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tui-date-table-body" }
const _hoisted_2 = { class: "tui-date-table-body-weeks" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "tui-date-table-body-days-item-cell-text" }

import {
  computed,
  ref,
  getCurrentInstance,
  nextTick,
  watch,
} from '../../../adapter-vue';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/zh-cn';
import { DateCell, DateCellType } from './date-picker';
import { isPC } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'date-table',
  props: {
  type: {
    type: String,
    default: 'range', // "single"/"range"
  },
  currentPanelDate: {
    type: Dayjs,
    default: () => dayjs(),
  },
  // Unique attribute when type is single
  date: {
    type: Dayjs,
    default: null,
  },
  // Unique attribute when type is range
  startDate: {
    type: Dayjs,
    default: null,
  },
  endDate: {
    type: Dayjs,
    default: null,
  },
},
  emits: ['pick'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
// vue instance
const instance = getCurrentInstance();

const tableRows = ref<DateCell[][]>([[], [], [], [], [], []]);
const currentPanelDateObject = ref<typeof Dayjs>(
  dayjs(props.currentPanelDate || null),
);
const dateObject = ref<typeof Dayjs>(dayjs(props.date || null));
const startDateObject = ref<typeof Dayjs>(dayjs(props.startDate || null));
const endDateObject = ref<typeof Dayjs>(dayjs(props.endDate || null));

const WEEKS_CONSTANT = computed(() => {
  return dayjs.weekdaysShort();
});

const WEEKS = computed(() =>
  WEEKS_CONSTANT.value.map((w: string) => w.substring(1)),
);

const startDateOnTable = computed(() => {
  const startDayOfMonth = currentPanelDateObject.value?.startOf('month');
  return startDayOfMonth?.subtract(startDayOfMonth?.day() || 7, 'day');
});

// Table data
const rows = computed(() => {
  const rows_ = tableRows.value;
  const cols = WEEKS.value.length;

  const startOfMonth = currentPanelDateObject.value?.startOf('month');
  const startOfMonthDay = startOfMonth?.day() || 7; // day of this month first day
  const dateCountOfMonth = startOfMonth?.daysInMonth(); // total days of this month

  let count = 1;
  for (let row = 0; row < tableRows.value.length; row++) {
    for (let col = 0; col < cols; col++) {
      const cellDate = startDateOnTable.value?.add(count, 'day');
      const text = cellDate?.date();
      // For type === "single", select the entered date
      // For type === "range", select the entered start and end dates
      const isSelected
        = props.type === 'single'
        && cellDate?.format('YYYY-MM-DD')
        === dateObject.value?.format('YYYY-MM-DD');
      const isSelectedStart
        = props.type === 'range'
        && cellDate?.format('YYYY-MM-DD')
        === startDateObject.value?.format('YYYY-MM-DD');
      const isSelectedEnd
        = props.type === 'range'
        && cellDate?.format('YYYY-MM-DD')
        === endDateObject.value?.format('YYYY-MM-DD');
      // For type === "range", check if it is within the selected range.
      const isInRange
        = cellDate?.isSameOrBefore(endDateObject.value, 'day')
        && cellDate?.isSameOrAfter(startDateObject.value, 'day');
      let type: DateCellType = 'normal';
      if (count < startOfMonthDay) {
        // Prev month's date
        type = 'prev-month';
      } else if (count - startOfMonthDay >= dateCountOfMonth) {
        // Next month's date
        type = 'next-month';
      }
      rows_[row][col] = {
        type,
        date: cellDate,
        text,
        isSelected: isSelected || isSelectedStart || isSelectedEnd,
        isSelectedStart,
        isSelectedEnd,
        isInRange,
      };
      count++;
    }
  }
  return rows_;
});

const handlePick = (cell: DateCell) => {
  if (cell?.type !== 'normal') {
    return;
  }
  emit('pick', cell);
};

watch(
  () => [props.currentPanelDate, props.date, props.startDate, props.endDate],
  () => {
    currentPanelDateObject.value = dayjs(props.currentPanelDate || null);
    dateObject.value = dayjs(props.date || null);
    startDateObject.value = dayjs(props.startDate || null);
    endDateObject.value = dayjs(props.endDate || null);
    nextTick(() => {
      instance?.proxy?.$forceUpdate();
    });
  },
  {
    deep: true,
    immediate: true,
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass(['tui-date-table', !_unref(isPC) && 'tui-date-table-h5']),
    cellspacing: "0",
    cellpadding: "0",
    role: "grid"
  }, [
    _createElementVNode("tbody", _hoisted_1, [
      _createElementVNode("tr", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(WEEKS), (item) => {
          return (_openBlock(), _createElementBlock("th", {
            key: item,
            class: "tui-date-table-body-weeks-item",
            "aria-label": item + '',
            scope: "col"
          }, _toDisplayString(_unref(TUITranslateService).t(`time.${item}`)), 9, _hoisted_3))
        }), 128))
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rows), (row, rowKey) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: rowKey,
          class: "tui-date-table-body-days"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (col, colKey) => {
            return (_openBlock(), _createElementBlock("td", {
              key: colKey,
              class: _normalizeClass(['tui-date-table-body-days-item', col.type])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([
              'tui-date-table-body-days-item-cell',
              col.isSelected && 'selected',
              col.isSelectedStart && 'selected-start',
              col.isSelectedEnd && 'selected-end',
              col.isInRange && 'range',
            ]),
                onClick: ($event: any) => (handlePick(col))
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(col.text), 1)
              ], 10, _hoisted_4)
            ], 2))
          }), 128))
        ]))
      }), 128))
    ])
  ], 2))
}
}

})