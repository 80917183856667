import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { isPC } from '../../../utils/env';
import Dialog from '../Dialog/index.vue';
import Transfer from '../Transfer/index.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  isRadio: {
    type: Boolean,
    default: false,
  },
  isNeedSearch: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  userList: {
    type: Array,
    default: () => ([]),
  },
  total: {
    type: Number,
    default: 0,
  },
},
  emits: ['complete', 'search', 'getMore'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const props = __props;

const reset = () => {
  emits('complete', []);
};

const submit = (dataList: any) => {
  emits('complete', dataList);
};

const handleSearchUser = (userID: string) => {
  console.log('userID: ', userID)
  emits('search', userID);
};

const handleGetMore = () => {
  emits('getMore');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Dialog, {
    show: true,
    isH5: !_unref(isPC),
    isHeaderShow: false,
    isFooterShow: false,
    background: false,
    "onUpdate:show": reset
  }, {
    default: _withCtx(() => [
      _createVNode(Transfer, {
        isSearch: props.isNeedSearch,
        title: props.title,
        list: props.userList,
        isH5: !_unref(isPC),
        isRadio: props.isRadio,
        total: props.total,
        onGetMore: handleGetMore,
        onSearch: handleSearchUser,
        onSubmit: submit,
        onCancel: reset
      }, null, 8, ["isSearch", "title", "list", "isH5", "isRadio", "total"])
    ]),
    _: 1
  }, 8, ["isH5"]))
}
}

})