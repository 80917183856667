import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 1,
  class: "none-view"
}

import { onMounted } from 'vue';
import { TUIKit } from './TUIKit';
import { TUICallKit } from '@tencentcloud/call-uikit-vue';
import { ref } from './TUIKit/adapter-vue';
import { getQuerySignApi } from '@/utils/user';
import { Toast, TOAST_TYPE } from '@/TUIKit/components/common/Toast/index';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const SDKAppID = ref<number>();
const userID = ref<string>('');
const userSig = ref<string>('');

const fetchUserInfo = async () => {
  try {
    const response = await getQuerySignApi();
    const data = response?.body?.imClient || {};
    SDKAppID.value = data.sdkAppId;
    userID.value = data.userId;
    userSig.value = data.sign;
  } catch (error) {
    Toast({
      message: error?.head?.msg || '获取客服信息失败',
      type: TOAST_TYPE.ERROR,
    });
  }
};

onMounted(() => {
  fetchUserInfo();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(SDKAppID))
      ? (_openBlock(), _createBlock(_unref(TUIKit), {
          key: 0,
          SDKAppID: _unref(SDKAppID),
          userID: _unref(userID),
          userSig: _unref(userSig)
        }, null, 8, ["SDKAppID", "userID", "userSig"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createTextVNode(" IM配置信息不全，无法拉起会话 ")),
          _createElementVNode("button", {
            class: "btn btn-default",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (fetchUserInfo()))
          }, "重新获取")
        ])),
    _createVNode(_unref(TUICallKit), {
      class: "callkit-container",
      allowedMinimized: true,
      allowedFullScreen: false
    })
  ]))
}
}

})