import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

import { TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import { ref, watchEffect } from '../../adapter-vue';
import { isPC, isUniFrameWork } from '../../utils/env';

import SelectFriend from './select-friend/index.vue';
import ContactSearch from './contact-search/index.vue';
import ContactList from './contact-list/index.vue';
import ContactInfo from './contact-info/index.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  // web/h5 single page application display format, uniapp please ignore
  displayType: {
    type: String,
    default: 'contactList', // "contactList" /  "selectFriend"
    require: false,
  },
},
  emits: ['switchConversation'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const props = __props;

const displayTypeRef = ref<string>(props.displayType || 'contactList');
const isShowSelectFriend = ref(false);
const isShowContactList = ref(true);
const isShowContactInfo = ref(true);

watchEffect(() => {
  isShowContactList.value = props?.displayType !== 'selectFriend';
});

TUIStore.watch(StoreName.CUSTOM, {
  isShowSelectFriendComponent: (data: any) => {
    if (!isUniFrameWork && props?.displayType === 'selectFriend') {
      isShowSelectFriend.value = data;
      isShowContactList.value = false;
      return;
    }
    if (data) {
      isShowSelectFriend.value = true;
      if (isUniFrameWork) {
        displayTypeRef.value = 'selectFriend';
        TUIGlobal?.hideTabBar();
      }
    } else {
      isShowSelectFriend.value = false;
      if (isUniFrameWork) {
        displayTypeRef.value = props.displayType;
        TUIGlobal?.showTabBar()?.catch(() => { /* ignore */ });
      }
    }
  },
  currentContactInfo: (contactInfo: any) => {
    isShowContactInfo.value = isPC || (contactInfo && typeof contactInfo === 'object' && Object.keys(contactInfo)?.length > 0);
  },
});

const switchConversation = (data: any) => {
  isUniFrameWork
  && TUIGlobal?.navigateTo({
    url: '/TUIKit/components/TUIChat/index',
  });
  emits('switchConversation', data);
};


return (_ctx: any,_cache: any) => {
  return (_unref(isShowSelectFriend))
    ? (_openBlock(), _createBlock(SelectFriend, { key: 0 }))
    : (_unref(isShowContactList))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(['tui-contact', !_unref(isPC) && 'tui-contact-h5'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(['tui-contact-left', !_unref(isPC) && 'tui-contact-h5-left'])
          }, [
            _createVNode(ContactSearch),
            _createVNode(ContactList, {
              class: _normalizeClass(['tui-contact-left-list', !_unref(isPC) && 'tui-contact-h5-left-list'])
            }, null, 8, ["class"])
          ], 2),
          (_unref(isShowContactInfo))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(['tui-contact-right', !_unref(isPC) && 'tui-contact-h5-right'])
              }, [
                _createVNode(ContactInfo, { onSwitchConversation: switchConversation })
              ], 2))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
}
}

})