import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "chat-header-container" }
const _hoisted_2 = ["onClick"]

import { ref, onMounted, onUnmounted, withDefaults } from '../../../adapter-vue';
import {
  TUIStore,
  StoreName,
  TUITranslateService,
  IConversationModel,
} from '@tencentcloud/chat-uikit-engine';
import { TUIConstants, ExtensionInfo } from '@tencentcloud/tui-core';
// import { JoinGroupCard } from '@tencentcloud/call-uikit-vue';
import Icon from '../../common/Icon.vue';
import backSVG from '../../../assets/icon/back.svg';
import { isPC } from '../../../utils/env';
import TUIChatConfig from '../config';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    headerExtensionList: { default: () => ([]) }
  },
  emits: ['closeChat'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;
const currentConversation = ref<IConversationModel>();
const currentConversationName = ref('');
const typingStatus = ref(false);
const groupID = ref('');
const isNotRoomChat = ref<boolean>(TUIChatConfig.getChatType() !== TUIConstants.TUIChat.TYPE.ROOM);

onMounted(() => {
  TUIStore.watch(StoreName.CONV, {
    currentConversation: onCurrentConversationUpdated,
  });

  TUIStore.watch(StoreName.CHAT, {
    typingStatus: onTypingStatusUpdated,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.CONV, {
    currentConversation: onCurrentConversationUpdated,
  });

  TUIStore.unwatch(StoreName.CHAT, {
    typingStatus: onTypingStatusUpdated,
  });
});

const closeChat = (conversationID: string | undefined) => {
  emits('closeChat', conversationID);
};

const handleExtensions = (item: ExtensionInfo) => {
  item.listener.onClicked?.({ groupID: groupID.value });
};

function onCurrentConversationUpdated(conversation: IConversationModel) {
  currentConversation.value = conversation;
  groupID.value = currentConversation.value?.groupProfile?.groupID;
  currentConversationName.value = currentConversation?.value?.getShowName();
}

function onTypingStatusUpdated(status: boolean) {
  typingStatus.value = status;
  if (typingStatus.value) {
    currentConversationName.value = TUITranslateService.t('TUIChat.对方正在输入');
  } else {
    currentConversationName.value = currentConversation.value?.getShowName() || '';
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['chat-header', !_unref(isPC) && 'chat-header-h5'])
  }, [
    (!_unref(isPC) && _unref(isNotRoomChat))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['chat-header-back', !_unref(isPC) && 'chat-header-h5-back']),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (closeChat(_unref(currentConversation).conversationID)))
        }, [
          _createVNode(Icon, { file: _unref(backSVG) }, null, 8, ["file"])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_unref(isNotRoomChat))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['chat-header-content', !_unref(isPC) && 'chat-header-h5-content'])
          }, _toDisplayString(_unref(currentConversationName)), 3))
        : _createCommentVNode("", true),
      _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['chat-header-setting', !_unref(isPC) && 'chat-header-h5-setting'])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.headerExtensionList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          onClick: _withModifiers(($event: any) => (handleExtensions(item)), ["stop"])
        }, [
          _createVNode(Icon, {
            file: item.icon
          }, null, 8, ["file"])
        ], 8, _hoisted_2))
      }), 128))
    ], 2)
  ], 2))
}
}

})