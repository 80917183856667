import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "application-tips"
}
const _hoisted_2 = {
  class: /*@__PURE__*/_normalizeClass({
          'application-contaienr': true
        })
}
const _hoisted_3 = { class: "application-header" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "application-item-info" }
const _hoisted_6 = { class: "application-item-nick" }
const _hoisted_7 = { class: "application-item-note" }
const _hoisted_8 = { class: "application-item-operation" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]

import { ref, onMounted, onUnmounted, watch } from '../../../../adapter-vue';
import {
  TUIStore,
  StoreName,
  TUITranslateService,
  TUIUserService,
  TUIGroupService,
} from '@tencentcloud/chat-uikit-engine';
import Icon from '../../../common/Icon.vue';
import Avatar from '../../../common/Avatar/index.vue';
import Drawer from '../../../common/Drawer/index.vue';
import closeIcon from '../../../../assets/icon/close-dark.svg';
import { isPC, isMobile } from '../../../../utils/env';
import { IGroupApplication, IUserProfile, IChatResponese } from '../../../../interface';

interface IProps {
  groupID: string;
}
interface ICustomGroupApplication {
  nick: string;
  avatar: string;
  isRemoved: boolean;
  application: IGroupApplication;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    groupID: { default: '' }
  },
  setup(__props: any) {

const props = __props;

const drawerDomInstanceRef = ref<InstanceType<typeof Drawer>>();
const groupApplicationCount = ref(0);
const isGroupApplicationDrawerShow = ref(false);
const customGroupApplicationList = ref<ICustomGroupApplication[]>([]);

watch(isGroupApplicationDrawerShow, (newVal) => {
  if (newVal) {
    generateCustomGroupApplicationList().then((list) => {
      customGroupApplicationList.value = list;
      groupApplicationCount.value = list.length;
    });
  }
});

watch(() => customGroupApplicationList.value.length, (newVal, oldVal) => {
  if (oldVal > 0 && newVal === 0) {
    isGroupApplicationDrawerShow.value = false;
  }
});

/**
 * Retrieves the current group application list based on the provided groupID.
 *
 * @return {Promise<IGroupApplication[]>} The list of group applications for the current group.
 */
async function getCurrentGroupApplicationList(): Promise<IGroupApplication[]> {
  const result: IChatResponese<{ applicationList: IGroupApplication[] }> = await TUIGroupService.getGroupApplicationList();
  const currentGroupApplicationList = result.data.applicationList.filter(application => application.groupID === props.groupID);
  return currentGroupApplicationList;
}

function toggleGroupApplicationDrawerShow() {
  isGroupApplicationDrawerShow.value = !isGroupApplicationDrawerShow.value;
}

async function generateCustomGroupApplicationList(): Promise<ICustomGroupApplication[]> {
  const applicationList = await getCurrentGroupApplicationList();
  if (applicationList.length === 0) {
    return [];
  }
  const userIDList = applicationList.map(application => application.applicationType === 0 ? application.applicant : application.userID);
  const { data: userProfileList } = await TUIUserService.getUserProfile({ userIDList }) as IChatResponese<IUserProfile[]>;
  const mappingFromUserID2Profile: Record<string, IUserProfile> = {};
  userProfileList.forEach((profile: IUserProfile) => {
    mappingFromUserID2Profile[profile.userID] = profile;
  });
  const groupApplicationList: ICustomGroupApplication[] = applicationList.map((application) => {
    const profile = mappingFromUserID2Profile[application.applicationType === 0 ? application.applicant : application.userID];
    return {
      nick: profile.nick || profile.userID || 'anonymous',
      avatar: profile.avatar || '',
      isRemoved: false,
      application: application,
    };
  });

  return groupApplicationList;
}

function handleApplication(customApplication: ICustomGroupApplication, action: 'Agree' | 'Reject', index: number) {
  TUIGroupService.handleGroupApplication({
    handleAction: action,
    application: customApplication.application,
  }).then(() => {
    customGroupApplicationList.value[index].isRemoved = true;
    setTimeout(() => {
      customGroupApplicationList.value.splice(index, 1);
      groupApplicationCount.value -= 1;
    }, 150);
  }).catch(() => {
    // TODO: handle error
  });
}

// --------------- mounted function ---------------
onMounted(() => {
  // get current group application number on the first time entering the group
  getCurrentGroupApplicationList().then((applicationList) => {
    groupApplicationCount.value = applicationList.length;
  });

  TUIStore.watch(StoreName.GRP, {
    groupSystemNoticeList: onGroupSystemNoticeListUpdated,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.GRP, {
    groupSystemNoticeList: onGroupSystemNoticeListUpdated,
  });
});

function onGroupSystemNoticeListUpdated() {
  // Approving or rejecting existing applications will not trigger this callback, but new applications can trigger it.
  generateCustomGroupApplicationList().then((list) => {
    customGroupApplicationList.value = list;
    groupApplicationCount.value = list.length;
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(groupApplicationCount) > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, _toDisplayString(_unref(groupApplicationCount)) + _toDisplayString(_unref(TUITranslateService).t("TUIChat.条入群申请")), 1),
          _createElementVNode("div", {
            class: "application-tips-btn",
            onClick: toggleGroupApplicationDrawerShow
          }, _toDisplayString(_unref(TUITranslateService).t("TUIChat.点击处理")), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(Drawer, {
      ref_key: "drawerDomInstanceRef",
      ref: drawerDomInstanceRef,
      visible: _unref(isGroupApplicationDrawerShow),
      zIndex: 998,
      popDirection: _unref(isMobile) ? 'bottom' : 'right',
      isFullScreen: _unref(isMobile),
      overlayColor: _unref(isMobile) ? undefined : 'transparent',
      drawerStyle: {
        bottom: {
          minHeight: '60vh',
          maxHeight: '80vh',
          borderRadius: '12px 12px 0 0',
        },
        right: {
          width: '360px',
          borderRadius: '12px 0 0 12px',
          boxShadow: '0 0 10px 0 #d0d0d0',
        }
      },
      onOnOverlayClick: toggleGroupApplicationDrawerShow
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("header", _hoisted_3, [
            _createElementVNode("div", { onClick: toggleGroupApplicationDrawerShow }, [
              (_unref(isPC))
                ? (_openBlock(), _createBlock(Icon, {
                    key: 0,
                    file: _unref(closeIcon),
                    size: '16px'
                  }, null, 8, ["file"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(TUITranslateService).t('关闭')), 1))
            ])
          ]),
          _createElementVNode("main", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(customGroupApplicationList), (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.nick,
                class: _normalizeClass({
              'application-item': true,
              'removed': item.isRemoved,
            })
              }, [
                _createVNode(Avatar, {
                  style: {
                flex: '0 0 auto',
              },
                  url: item.avatar,
                  useSkeletonAnimation: true
                }, null, 8, ["url"]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(item.nick), 1),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(TUITranslateService).t("TUIChat.申请加入")), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", {
                    class: "agree",
                    onClick: ($event: any) => (handleApplication(item, 'Agree', index))
                  }, _toDisplayString(_unref(TUITranslateService).t("TUIChat.同意")), 9, _hoisted_9),
                  _createElementVNode("div", {
                    class: "reject",
                    onClick: ($event: any) => (handleApplication(item, 'Reject', index))
                  }, _toDisplayString(_unref(TUITranslateService).t("TUIChat.拒绝")), 9, _hoisted_10)
                ])
              ], 2))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "popDirection", "isFullScreen", "overlayColor"])
  ]))
}
}

})