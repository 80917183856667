import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = {
  key: 0,
  class: "text"
}
const _hoisted_4 = ["src", "alt"]

import { watchEffect, ref } from '../../../../adapter-vue';
import { CUSTOM_BASIC_EMOJI_URL, CUSTOM_BASIC_EMOJI_URL_MAPPING } from '../../emoji-config';
import { isPC } from '../../../../utils/env';
interface IProps {
  content: Record<string, any>;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'message-text',
  props: {
    content: { default: () => ({}) }
  },
  setup(__props: any) {

const props = __props;
const data = ref();
const textName = ref('');
watchEffect(() => {
  if(props.content.MsgType) {
    textName.value = props.content.MsgContent?.Text || '';
  }else {
    data.value = props.content;
    data.value.text?.forEach((item: { name: string; text?: string; src?: string; type?: string; emojiKey?: string }) => {
      if (item.name === 'img' && item?.type === 'custom') {
        if (!CUSTOM_BASIC_EMOJI_URL) {
          console.warn('CUSTOM_BASIC_EMOJI_URL is required for custom emoji, please check your CUSTOM_BASIC_EMOJI_URL.');
        } else if (!item.emojiKey || !CUSTOM_BASIC_EMOJI_URL_MAPPING[item.emojiKey]) {
          console.warn('emojiKey is required for custom emoji, please check your emojiKey.');
        } else {
          item.src = CUSTOM_BASIC_EMOJI_URL + CUSTOM_BASIC_EMOJI_URL_MAPPING[item.emojiKey];
        }
      }
    });
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['message-text-container', _unref(isPC) && 'text-select'])
  }, [
    (props.content.MsgType)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(textName)), 1)
        ]))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(data).text, (item, index) => {
          return (_openBlock(), _createElementBlock("span", { key: index }, [
            (item.name === 'text')
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.text), 1))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "emoji",
                  src: item.src,
                  alt: item.emojiKey
                }, null, 8, _hoisted_4))
          ]))
        }), 128))
  ], 2))
}
}

})