import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

import { ref, watch, withDefaults } from '../../../adapter-vue';
export interface IOverlayProps {
  visible?: boolean;
  zIndex?: number | undefined;
  useMask?: boolean | undefined;
  maskColor?: string | undefined;
  isFullScreen?: boolean | undefined;
  width?: string;
  height?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    visible: { type: Boolean, default: true },
    zIndex: { default: 9999 },
    useMask: { type: Boolean, default: true },
    maskColor: { default: 'rgba(0, 0, 0, 0.6)' },
    isFullScreen: { type: Boolean, default: true },
    width: { default: 'auto' },
    height: { default: 'auto' }
  },
  emits: ['onOverlayClick'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const emits = __emit;

const props = __props;

const overlayDomRef = ref<HTMLElement>();
const isOverlayShow = ref<boolean>(props.visible);

watch(() => props.visible, (visible: boolean) => {
  if (visible) {
    isOverlayShow.value = true;
  } else {
    setTimeout(() => {
      isOverlayShow.value = false;
    }, 150);
  }
}, {
  immediate: true,
});

function onOverlayClick() {
  emits('onOverlayClick');
}

__expose({
  overlayDomRef,
});

return (_ctx: any,_cache: any) => {
  return (_unref(isOverlayShow))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "overlayDomRef",
        ref: overlayDomRef,
        class: "overlay-container",
        style: _normalizeStyle({
      position: props.isFullScreen ? 'fixed' : 'absolute',
      zIndex: props.zIndex,
    })
      }, [
        (props.useMask)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass({
        'overlay-mask': true,
        'fade-in': props.visible,
      }),
              style: _normalizeStyle({
        backgroundColor: props.maskColor,
      }),
              onClick: onOverlayClick,
              onTouchstart: _withModifiers(onOverlayClick, ["prevent","stop"])
            }, null, 38))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass({
        'overlay-content': true,
        'full-screen': props.isFullScreen,
      })
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ], 4))
    : _createCommentVNode("", true)
}
}

})