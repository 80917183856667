import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "admin-main" }
const _hoisted_2 = { class: "admin-manage" }
const _hoisted_3 = { class: "admin-manage-header" }
const _hoisted_4 = { class: "admin-manage-list" }
const _hoisted_5 = { class: "item-main" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "item-name" }
const _hoisted_8 = { class: "admin-manage-list-item" }
const _hoisted_9 = { class: "admin-manage-list-item" }
const _hoisted_10 = {
  key: 0,
  class: "admin-mute-all"
}
const _hoisted_11 = { class: "admin-mute-all-title" }
const _hoisted_12 = { class: "admin-mute-all-content" }
const _hoisted_13 = {
  key: 1,
  class: "admin-mute"
}
const _hoisted_14 = { class: "admin-mute-header" }
const _hoisted_15 = { class: "admin-mute-list" }
const _hoisted_16 = { class: "item-main" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "item-name" }
const _hoisted_19 = { class: "admin-mute-list-item" }
const _hoisted_20 = { class: "admin-mute-list-item" }

import {
  TUITranslateService,
  IGroupModel,
} from '@tencentcloud/chat-uikit-engine';
import { watchEffect, ref } from '../../../adapter-vue';
import Slider from '../../common/Slider/index.vue';
import Icon from '../../common/Icon.vue';
import plusSVG from '../../../assets/icon/plus.svg';
import minusSVG from '../../../assets/icon/minus.svg';
import { IGroupMember } from '../../../interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'manage-admin',
  props: {
  member: {
    type: Object,
    default: () => {},
  },
  isSetMuteTime: {
    type: Boolean,
    default: () => false,
  },
  currentGroup: {
    type: Object,
    default: () => {},
  },
},
  emits: [
  'addAdmin',
  'removeAdmin',
  'setAllMuteTime',
  'addMute',
  'removeMute',
  'close',
],
  setup(__props, { emit: __emit }) {

const props = __props;

const isAdminSetMuteTime = ref(false);
const memberAdmin = ref({
  admin: [] as Array<IGroupMember>,
  member: [] as Array<IGroupMember>,
  muteMember: [] as Array<IGroupMember>,
});
const currentGroupAdmin = ref<IGroupModel>();

watchEffect(() => {
  memberAdmin.value = props.member as {
    admin: Array<IGroupMember>;
    member: Array<IGroupMember>;
    muteMember: Array<IGroupMember>;
  };
  isAdminSetMuteTime.value = props.isSetMuteTime;
  currentGroupAdmin.value = props.currentGroup;
});

const emits = __emit;

const addAdmin = () => {
  emits('addAdmin');
};

const removeAdmin = () => {
  emits('removeAdmin');
};

const setAllMuteTime = (value: boolean) => {
  emits('setAllMuteTime', value);
};

const addMute = () => {
  emits('addMute');
};

const removeMute = () => {
  emits('removeMute');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.群管理员`)), 1),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(memberAdmin).admin, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "admin-manage-list-item"
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                class: "item-main-avatar",
                src: 
                item.avatar ||
                  'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
              ,
                onerror: "this.onerror=null;this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
              }, null, 8, _hoisted_6)
            ]),
            _createElementVNode("div", _hoisted_7, _toDisplayString(item.nick || item.userID), 1)
          ]))
        }), 128)),
        _createElementVNode("li", _hoisted_8, [
          _createElementVNode("div", {
            class: "item-main",
            onClick: addAdmin
          }, [
            _createVNode(Icon, {
              file: _unref(plusSVG),
              width: "16px",
              height: "16px"
            }, null, 8, ["file"])
          ])
        ]),
        _createElementVNode("li", _hoisted_9, [
          (_unref(memberAdmin).admin.length > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "item-main",
                onClick: removeAdmin
              }, [
                _createVNode(Icon, {
                  file: _unref(minusSVG),
                  width: "16px",
                  height: "16px"
                }, null, 8, ["file"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_unref(isAdminSetMuteTime))
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.全员禁言`)), 1),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(TUITranslateService).t(
              `TUIGroup.全员禁言开启后，只允许群主和管理员发言。`
            )), 1)
          ]),
          _createVNode(Slider, {
            open: _unref(currentGroupAdmin).muteAllMembers,
            onChange: setAllMuteTime
          }, null, 8, ["open"])
        ]))
      : _createCommentVNode("", true),
    (_unref(isAdminSetMuteTime))
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.单独禁言人员`)), 1),
          _createElementVNode("ul", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(memberAdmin).muteMember, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "admin-mute-list-item"
              }, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("img", {
                    class: "item-main-avatar",
                    src: 
                item.avatar ||
                  'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
              ,
                    onerror: "this.onerror=null;this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
                  }, null, 8, _hoisted_17)
                ]),
                _createElementVNode("div", _hoisted_18, _toDisplayString(item.nick || item.userID), 1)
              ]))
            }), 128)),
            _createElementVNode("li", _hoisted_19, [
              _createElementVNode("div", {
                class: "item-main",
                onClick: addMute
              }, [
                _createVNode(Icon, {
                  file: _unref(plusSVG),
                  width: "16px",
                  height: "16px"
                }, null, 8, ["file"])
              ])
            ]),
            _createElementVNode("li", _hoisted_20, [
              (_unref(memberAdmin).muteMember.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "item-main",
                    onClick: removeMute
                  }, [
                    _createVNode(Icon, {
                      file: _unref(minusSVG),
                      width: "16px",
                      height: "16px"
                    }, null, 8, ["file"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})