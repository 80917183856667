<template>
  <div id="app">
    <!-- <TUIKit :SDKAppID="1600047277" userID="administrator" userSig="eJwtjMsOgjAUBf*lawPXSikhccECRXRDfMQQNk1a5GLAWqrRGP9dBJZn5mQ*5LDbO09lSEioA2Q2bJSqtVjigIVssMXOGmFvZjp08iq0RknCuQ8AHqecj0a9NBrVc8YY7dVILTZ-xqkPlAUenSp46ftZVLh1WsEJbLdW2bF8RNskTpN88V6d8*CeVhhvau4Xrq7jJfn*ALVfNQA_" /> -->
    <!-- <TUIKit :SDKAppID="1600047277" userID="594@patient" userSig="eJwtzNsKgkAUheF32dcheyZzRiEICSQ6ghnpnekY21DGAyJE796gXq7vh-WF*ym0BtWCB9xCWE2bclX3VNDEG9fe6bQnQ0vu8k*qNeXgMQcRbcGFmIsaNbUKPAdtiThbT5URJtZMuhKdRTt6m*8jC0J6Pso2OpeHJtu--CoekzpWVZDxrNE3n64jL*LLEG3h9wcZ2DO0" /> -->
    <!-- <TUIKit :SDKAppID="1600047277" userID="mdclient" userSig="eJwtjL0OgjAYAN*lq4a0xdpC4uKimMaFyk7oV-kCaAPE*BPf3QqMd5fchxidRw-oSUp4RMl6YrRwG9HhpDtbtRh4aYNtSu-RkpRtKaUbyaWcCzw99hC8EIKHNNsRu7*TMUsUVyxZLngNa2jA6ZNuhnNZrMDKLPb3w9EVyuzNKxH5Bdv3WNVZjdmOfH--fDMv" /> -->
    <TUIKit v-if="SDKAppID" :SDKAppID="SDKAppID" :userID="userID" :userSig="userSig" />
    <div v-else class="none-view">
      IM配置信息不全，无法拉起会话
      <button class="btn btn-default" @click="fetchUserInfo()">重新获取</button>
    </div>
    <TUICallKit class="callkit-container" :allowedMinimized="true" :allowedFullScreen="false" />
  </div>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue';
import { TUIKit } from './TUIKit';
import { TUICallKit } from '@tencentcloud/call-uikit-vue';
import { ref } from './TUIKit/adapter-vue';
import { getQuerySignApi } from '@/utils/user';
import { Toast, TOAST_TYPE } from '@/TUIKit/components/common/Toast/index';

const SDKAppID = ref<number>();
const userID = ref<string>('');
const userSig = ref<string>('');

const fetchUserInfo = async () => {
  try {
    const response = await getQuerySignApi();
    const data = response?.body?.imClient || {};
    SDKAppID.value = data.sdkAppId;
    userID.value = data.userId;
    userSig.value = data.sign;
  } catch (error) {
    Toast({
      message: error?.head?.msg || '获取客服信息失败',
      type: TOAST_TYPE.ERROR,
    });
  }
};

onMounted(() => {
  fetchUserInfo();
});

</script>
<style lang="scss">
</style>

<style scoped lang="scss">
.none-view {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #909399;
  .btn {
    margin-top: 10px;
    font-weight: 400;
    color: #FFF;
    letter-spacing: 0;
    border-radius: 3px;
    cursor: pointer;

    &-cancel {
      border: 1px solid #ddd;
      color: #666;
    }

    &-default {
      background: #006EFF;
      border: 1px solid #006EFF;
    }
  }
}
</style>
