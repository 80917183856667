import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "image-counter" }

import { ref, watchEffect, onMounted, onUnmounted, withDefaults } from '../../../adapter-vue';
import { IMessageModel, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal, getPlatform } from '@tencentcloud/universal-api';
import Icon from '../../common/Icon.vue';
import iconClose from '../../../assets/icon/icon-close.svg';
import iconArrowLeft from '../../../assets/icon/icon-arrow-left.svg';
import iconZoomIn from '../../../assets/icon/zoom-in.svg';
import iconZoomOut from '../../../assets/icon/zoom-out.svg';
import iconRotateLeft from '../../../assets/icon/rotate-left.svg';
import iconRotateRight from '../../../assets/icon/rotate-right.svg';
import iconDownload from '../../../assets/icon/download.svg';
import ImageItem from './image-item.vue';
import { Toast, TOAST_TYPE } from '../../common/Toast/index';
import { isPC, isMobile, isUniFrameWork } from '../../../utils/env';

interface touchesPosition {
  pageX1?: number;
  pageY1?: number;
  pageX2?: number;
  pageY2?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    imageList: { default: () => ([] as IMessageModel[]) },
    currentImage: {}
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const imageFormatMap = new Map([
  [1, 'jpg'],
  [2, 'gif'],
  [3, 'png'],
  [4, 'bmp'],
]);

const emit = __emit;
const zoom = ref(1);
const rotate = ref(0);
const minZoom = ref(0.1);
const currentImageIndex = ref(0);
const image = ref();
const ulRef = ref();
// touch
let startX = 0;
const touchStore = {} as touchesPosition;
let moveFlag = false;
let twoTouchesFlag = false;
let timer: number | null = null;

watchEffect(() => {
  currentImageIndex.value = props.imageList.findIndex((message: any) => {
    return message.ID === props?.currentImage?.ID;
  });
});

const isNumber = (value: any) => {
  return typeof value === 'number' && isFinite(value);
};

const handleTouchStart = (e: any) => {
  e.preventDefault();
  moveInit(e);
  twoTouchesInit(e);
};

const handleTouchMove = (e: any) => {
  e.preventDefault();
  moveFlag = true;
  if (e.touches && e.touches.length === 2) {
    twoTouchesFlag = true;
    handleTwoTouches(e);
  }
};

const handleTouchEnd = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
  let moveEndX = 0;
  let X = 0;
  if (twoTouchesFlag) {
    if (!timer) {
      twoTouchesFlag = false;
      delete touchStore.pageX2;
      delete touchStore.pageY2;
      timer = setTimeout(() => {
        timer = null;
      }, 200);
    }
    return;
  }
  // H5 touch move to left to go to prev image
  // H5 touch move to right to go to next image
  if (timer === null) {
    switch (moveFlag) {
      // touch event
      case true:
        moveEndX = e?.changedTouches[0]?.pageX;
        X = moveEndX - startX;
        if (X > 100) {
          goPrev();
        } else if (X < -100) {
          goNext();
        }
        break;
      // click event
      case false:
        close();
        break;
    }
    timer = setTimeout(() => {
      timer = null;
    }, 200);
  }
};

const handleTouchCancel = () => {
  twoTouchesFlag = false;
  delete touchStore.pageX1;
  delete touchStore.pageY1;
};

const handleWheel = (e: any) => {
  e.preventDefault();
  if (Math.abs(e.deltaX) !== 0 && Math.abs(e.deltaY) !== 0) return;
  let scale = zoom.value;
  scale += e.deltaY * (e.ctrlKey ? -0.01 : 0.002);
  scale = Math.min(Math.max(0.125, scale), 4);
  zoom.value = scale;
};

const moveInit = (e: any) => {
  startX = e?.changedTouches[0]?.pageX;
  moveFlag = false;
};

const twoTouchesInit = (e: any) => {
  const touch1 = e?.touches[0];
  const touch2 = e?.touches[1];
  touchStore.pageX1 = touch1?.pageX;
  touchStore.pageY1 = touch1?.pageY;
  if (touch2) {
    touchStore.pageX2 = touch2?.pageX;
    touchStore.pageY2 = touch2?.pageY;
  }
};

const handleTwoTouches = (e: any) => {
  const touch1 = e?.touches[0];
  const touch2 = e?.touches[1];
  if (touch2) {
    if (!isNumber(touchStore.pageX2)) {
      touchStore.pageX2 = touch2.pageX;
    }
    if (!isNumber(touchStore.pageY2)) {
      touchStore.pageY2 = touch2.pageY;
    }
  }
  const getDistance = (
    startX: number,
    startY: number,
    stopX: number,
    stopY: number,
  ) => {
    return Math.hypot(stopX - startX, stopY - startY);
  };
  if (
    !isNumber(touchStore.pageX1)
    || !isNumber(touchStore.pageY1)
    || !isNumber(touchStore.pageX2)
    || !isNumber(touchStore.pageY2)
  ) {
    return;
  }
  const touchZoom
    = getDistance(touch1.pageX, touch1.pageY, touch2.pageX, touch2.pageY)
    / getDistance(
      touchStore.pageX1 as number,
      touchStore.pageY1 as number,
      touchStore.pageX2 as number,
      touchStore.pageY2 as number,
    );
  zoom.value = Math.min(Math.max(0.5, zoom.value * touchZoom), 4);
};

onMounted(() => {
  // web: close on esc keydown
  document?.addEventListener
  && document?.addEventListener('keydown', handleEsc);
});

const handleEsc = (e: any) => {
  e.preventDefault();
  if (e?.keyCode === 27) {
    close();
  }
};
const zoomIn = () => {
  zoom.value += 0.1;
};
const zoomOut = () => {
  zoom.value
    = zoom.value - 0.1 > minZoom.value ? zoom.value - 0.1 : minZoom.value;
};
const close = () => {
  emit('close');
};
const rotateLeft = () => {
  rotate.value -= 90;
};
const rotateRight = () => {
  rotate.value += 90;
};
const goNext = () => {
  currentImageIndex.value < props.imageList.length - 1
  && currentImageIndex.value++;
  initStyle();
};
const goPrev = () => {
  currentImageIndex.value > 0 && currentImageIndex.value--;
  initStyle();
};
const initStyle = () => {
  zoom.value = 1;
  rotate.value = 0;
};

const getImageUrl = (message: IMessageModel) => {
  if (isPC) {
    return message?.payload?.imageInfoArray[0]?.url;
  } else {
    return message?.payload?.imageInfoArray[2]?.url;
  }
};

const save = () => {
  const imageMessage = props.imageList[
    currentImageIndex.value
  ] as IMessageModel;
  const imageSrc = imageMessage?.payload?.imageInfoArray[0]?.url;
  if (!imageSrc) {
    Toast({
      message: TUITranslateService.t('component.图片 url 不存在'),
      type: TOAST_TYPE.ERROR,
    });
    return;
  }
  switch (getPlatform()) {
    case 'wechat':
      // Get the user's current settings and get the album permissions
      TUIGlobal.getSetting({
        success: (res: any) => {
          if (!res?.authSetting['scope.writePhotosAlbum']) {
            TUIGlobal.authorize({
              scope: 'scope.writePhotosAlbum',
              success() {
                downloadImgInUni(imageSrc);
              },
              fail() {
                TUIGlobal.showModal({
                  title: '您已拒绝获取相册权限',
                  content: '是否进入权限管理，调整授权？',
                  success: (res: any) => {
                    if (res.confirm) {
                      // Call up the client applet settings interface and return the operation results set by the user.
                      // Ask the user to authorize again.
                      TUIGlobal.openSetting({
                        success: (res: any) => {
                          console.log(res.authSetting);
                        },
                      });
                    } else if (res.cancel) {
                      return Toast({
                        message: TUITranslateService.t('component.已取消'),
                        type: TOAST_TYPE.ERROR,
                      });
                    }
                  },
                });
              },
            });
          } else {
            // If you already have album permission, save directly to the album
            downloadImgInUni(imageSrc);
          }
        },
        fail: () => {
          Toast({
            message: TUITranslateService.t('component.获取权限失败'),
            type: TOAST_TYPE.ERROR,
          });
        },
      });
      break;
    case 'app':
      downloadImgInUni(imageSrc);
      break;
    default:
      downloadImgInWeb(imageSrc);
      break;
  }
};

const downloadImgInUni = (src: string) => {
  TUIGlobal.showLoading({
    title: '大图提取中',
  });
  TUIGlobal.downloadFile({
    url: src,
    success: function (res: any) {
      TUIGlobal.hideLoading();
      TUIGlobal.saveImageToPhotosAlbum({
        filePath: res.tempFilePath,
        success: () => {
          Toast({
            message: TUITranslateService.t('component.已保存至相册'),
            type: TOAST_TYPE.SUCCESS,
          });
        },
      });
    },
    fail: function () {
      TUIGlobal.hideLoading();
      Toast({
        message: TUITranslateService.t('component.图片下载失败'),
        type: TOAST_TYPE.ERROR,
      });
    },
  });
};

const downloadImgInWeb = (src: string) => {
  const option: any = {
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };
  const imageMessage = props.imageList[
    currentImageIndex.value
  ] as IMessageModel;
  const imageFormat: number = imageMessage?.payload?.imageFormat;
  if (!imageFormatMap.has(imageFormat)) {
    Toast({
      message: TUITranslateService.t('component.暂不支持下载此类型图片'),
      type: TOAST_TYPE.ERROR,
    });
    return;
  }
  // If the browser supports fetch, use blob to download, so as to avoid the browser clicking the a tag and jumping to the preview of the new page
  if ((window as any).fetch) {
    fetch(src, option)
      .then(res => res.blob())
      .then((blob) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = url + '.' + imageFormatMap.get(imageFormat);
        a.click();
      });
  } else {
    const a = document.createElement('a');
    a.href = src;
    a.target = '_blank';
    a.download = src + '.' + imageFormatMap.get(imageFormat);
    a.click();
  }
};

onUnmounted(() => {
  document?.removeEventListener
  && document?.removeEventListener('keydown', handleEsc);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["image-previewer", [_unref(isMobile) && 'image-previewer-h5']])
  }, [
    _createElementVNode("div", {
      ref_key: "image",
      ref: image,
      class: "image-wrapper",
      onTouchstart: _withModifiers(handleTouchStart, ["stop"]),
      onTouchmove: _withModifiers(handleTouchMove, ["stop"]),
      onTouchend: _withModifiers(handleTouchEnd, ["stop"]),
      onTouchcancel: _withModifiers(handleTouchCancel, ["stop"]),
      onWheel: _withModifiers(handleWheel, ["stop"])
    }, [
      _createElementVNode("ul", {
        ref_key: "ulRef",
        ref: ulRef,
        class: "image-list",
        style: _normalizeStyle({
          width: `${_ctx.imageList.length * 100}%`,
          transform: `translateX(-${
            (_unref(currentImageIndex) * 100) / _ctx.imageList.length
          }%)`,
          transition: '0.5s',
        })
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageList, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "image-item"
          }, [
            _createVNode(ImageItem, {
              zoom: _unref(zoom),
              rotate: _unref(rotate),
              src: getImageUrl(item),
              messageItem: item,
              class: _normalizeClass([_unref(isUniFrameWork) ? 'image-item' : ''])
            }, null, 8, ["zoom", "rotate", "src", "messageItem", "class"])
          ]))
        }), 128))
      ], 4)
    ], 544),
    _withDirectives(_createElementVNode("div", {
      class: "icon icon-close",
      onClick: close
    }, [
      _createVNode(Icon, {
        file: _unref(iconClose),
        width: "16px",
        height: "16px"
      }, null, 8, ["file"])
    ], 512), [
      [_vShow, _unref(isPC)]
    ]),
    (_unref(isPC) && _unref(currentImageIndex) > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "image-button image-button-left",
          onClick: goPrev
        }, [
          _createVNode(Icon, { file: _unref(iconArrowLeft) }, null, 8, ["file"])
        ]))
      : _createCommentVNode("", true),
    (_unref(isPC) && _unref(currentImageIndex) < _ctx.imageList.length - 1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "image-button image-button-right",
          onClick: goNext
        }, [
          _createVNode(Icon, { file: _unref(iconArrowLeft) }, null, 8, ["file"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(['actions-bar', _unref(isMobile) && 'actions-bar-h5'])
    }, [
      (_unref(isPC))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "icon-zoom-in",
            onClick: zoomIn
          }, [
            _createVNode(Icon, {
              file: _unref(iconZoomIn),
              width: "27px",
              height: "27px"
            }, null, 8, ["file"])
          ]))
        : _createCommentVNode("", true),
      (_unref(isPC))
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "icon-zoom-out",
            onClick: zoomOut
          }, [
            _createVNode(Icon, {
              file: _unref(iconZoomOut),
              width: "27px",
              height: "27px"
            }, null, 8, ["file"])
          ]))
        : _createCommentVNode("", true),
      (_unref(isPC))
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "icon-refresh-left",
            onClick: rotateLeft
          }, [
            _createVNode(Icon, {
              file: _unref(iconRotateLeft),
              width: "27px",
              height: "27px"
            }, null, 8, ["file"])
          ]))
        : _createCommentVNode("", true),
      (_unref(isPC))
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "icon-refresh-right",
            onClick: rotateRight
          }, [
            _createVNode(Icon, {
              file: _unref(iconRotateRight),
              width: "27px",
              height: "27px"
            }, null, 8, ["file"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(currentImageIndex) + 1) + " / " + _toDisplayString(_ctx.imageList.length), 1)
    ], 2),
    _createElementVNode("div", {
      class: "save",
      onClick: _withModifiers(save, ["stop","prevent"])
    }, [
      _createVNode(Icon, {
        file: _unref(iconDownload),
        width: "20px",
        height: "20px"
      }, null, 8, ["file"])
    ])
  ], 2))
}
}

})