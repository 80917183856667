import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tui-search-result-main"
}
const _hoisted_2 = { class: "tui-search-result-list" }
const _hoisted_3 = {
  key: 0,
  class: "header"
}
const _hoisted_4 = { class: "list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "more-text" }
const _hoisted_7 = {
  key: 1,
  class: "tui-search-message-header"
}
const _hoisted_8 = { class: "header-content" }
const _hoisted_9 = { class: "header-content-count normal" }
const _hoisted_10 = { class: "header-content-keyword" }
const _hoisted_11 = { class: "highlight" }
const _hoisted_12 = { class: "header-content-type normal" }
const _hoisted_13 = {
  key: 2,
  class: "tui-search-message-list"
}
const _hoisted_14 = {
  class: /*@__PURE__*/_normalizeClass(['list-group-date'])
}
const _hoisted_15 = { class: "more-text" }

import { ref, watch, computed, onMounted, onUnmounted } from '../../../adapter-vue';
import {
  TUITranslateService,
  TUIConversationService,
  TUIStore,
  StoreName,
  IMessageModel,
  SearchCloudMessagesParams,
} from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import SearchResultItem from './search-result-item/index.vue';
import SearchResultDefault from './search-result-default/index.vue';
import SearchResultLoading from './search-result-loading/index.vue';
import { searchMessageTypeList, searchMessageTypeDefault } from '../search-type-list';
import Icon from '../../common/Icon.vue';
import searchIcon from '../../../assets/icon/search.svg';
import enterIcon from '../../../assets/icon/right-icon.svg';
import {
  searchCloudMessages,
  enterConversation,
  generateSearchResultYMD,
  debounce,
} from '../utils';
import { enableSampleTaskStatus } from '../../../utils/enableSampleTaskStatus';
import { isPC, isUniFrameWork } from '../../../utils/env';
import { SEARCH_TYPE, ISearchInputValue, ISearchMessageType, ISearchMessageTime } from '../type';
import { ISearchCloudMessageResult, ISearchResultListItem } from '../../../interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  searchType: {
    type: String,
    default: 'global', // "global" / "conversation"
    validator(value: string) {
      return ['global', 'conversation'].includes(value);
    },
  },
},
  setup(__props) {

const props = __props;

// search params
const keywordList = ref<string[]>([]);
const messageTypeList = ref<string | string[]>(
  searchMessageTypeDefault[props.searchType as SEARCH_TYPE]?.value as string[],
);
const timePosition = ref<number>(0);
const timePeriod = ref<number>(0);
// Search by "and" after splitting the whole string by space
// For example: enter "111 222", search for messages with both 111 and 222, and also include messages that strictly search for "111 222"
const keywordListMatchType = ref<string>('and');

// current search tab key
const currentSearchTabKey = ref<string>(
  searchMessageTypeDefault[props.searchType as SEARCH_TYPE]?.key,
);

// search results all
const searchResult = ref<{
  [propsName: string]: { key: string; label: string; list: ISearchResultListItem[]; cursor: string | null };
}>({});
const searchAllMessageList = ref<ISearchResultListItem[]>([]);
const searchAllMessageTotalCount = ref<number>(0);

// search results detail
const currentSearchConversationID = ref<string>('');
const searchConversationResult = ref<ISearchCloudMessageResult>();
const searchConversationMessageList = ref<IMessageModel[]>([]);
const searchConversationMessageTotalCount = ref<number>();

// search results for file messages/image and video messages, grouped by timeline
const searchConversationResultGroupByDate = ref<
  Array<{ date: string; list: IMessageModel[] }>
>([]);

// ui display control
const isResultDetailShow = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const isSearchDetailLoading = ref<boolean>(false);
const isSearchDefaultShow = computed((): boolean => {
  if (isLoading.value) {
    return false;
  }
  if (props.searchType === 'global') {
    if (!keywordList?.value?.length || Object?.keys(searchResult.value)?.length) {
      return false;
    } else {
      return true;
    }
  } else {
    if (searchConversationMessageList?.value?.length) {
      return false;
    } else {
      return true;
    }
  }
});

function onCurrentConversationIDUpdate(id: string) {
  props.searchType === 'conversation' && (currentSearchConversationID.value = id);
}

function onCurrentSearchInputValueUpdate(obj: ISearchInputValue) {
  if (obj?.searchType === props?.searchType) {
    keywordList.value = obj?.value ? obj.value.trim().split(/\s+/) : [];
  }
}

function onCurrentSearchMessageTypeUpdate(typeObject: ISearchMessageType) {
  if (typeObject?.searchType === props?.searchType) {
    currentSearchTabKey.value
        = typeObject?.value?.key || searchMessageTypeDefault[props.searchType as SEARCH_TYPE]?.key;
    messageTypeList.value
        = typeObject?.value?.value
        || searchMessageTypeDefault[props.searchType as SEARCH_TYPE]?.value;
  }
}

function onCurrentSearchMessageTimeUpdate(timeObject: ISearchMessageTime) {
  if (timeObject?.searchType === props?.searchType) {
    timePosition.value = timeObject?.value?.value?.timePosition;
    timePeriod.value = timeObject?.value?.value?.timePeriod;
  }
}

onMounted(() => {
  TUIStore.watch(StoreName.CONV, {
    currentConversationID: onCurrentConversationIDUpdate,
  });
  TUIStore.watch(StoreName.SEARCH, {
    currentSearchInputValue: onCurrentSearchInputValueUpdate,
    currentSearchMessageType: onCurrentSearchMessageTypeUpdate,
    currentSearchMessageTime: onCurrentSearchMessageTimeUpdate,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.CONV, {
    currentConversationID: onCurrentConversationIDUpdate,
  });
  TUIStore.unwatch(StoreName.SEARCH, {
    currentSearchInputValue: onCurrentSearchInputValueUpdate,
    currentSearchMessageType: onCurrentSearchMessageTypeUpdate,
    currentSearchMessageTime: onCurrentSearchMessageTimeUpdate,
  });
});

const setMessageSearchResultList = (option?: { conversationID?: string | undefined; cursor?: string | undefined }) => {
  searchCloudMessages(
    {
      keywordList: keywordList?.value?.length ? keywordList.value : undefined,
      messageTypeList: typeof messageTypeList.value === 'string' ? [messageTypeList.value] : messageTypeList.value,
      timePosition: timePosition.value,
      timePeriod: timePeriod.value,
      conversationID: option?.conversationID || undefined,
      cursor: option?.cursor || undefined,
      keywordListMatchType: keywordListMatchType.value,
    } as SearchCloudMessagesParams,
  )
    .then((res: { data: ISearchCloudMessageResult }) => {
      enableSampleTaskStatus('searchCloudMessage');
      if (!option?.conversationID) {
        option?.cursor
          ? (searchAllMessageList.value = [
              ...searchAllMessageList.value,
              ...res.data.searchResultList,
            ])
          : (searchAllMessageList.value = res?.data?.searchResultList);
        searchAllMessageTotalCount.value = res?.data?.totalCount;
        const key = currentSearchTabKey.value === 'all' ? 'allMessage' : currentSearchTabKey.value;
        if (
          searchAllMessageList?.value?.length
          && currentSearchTabKey.value !== 'contact'
          && currentSearchTabKey.value !== 'group'
        ) {
          searchResult.value = Object.assign({}, searchResult.value, {
            [key]: {
              key,
              label: searchMessageTypeList[key].label,
              list: currentSearchTabKey.value === 'all'
                ? searchAllMessageList?.value?.slice(0, 3)
                : searchAllMessageList?.value,
              cursor: res?.data?.cursor || null,
            },
          });
        } else {
          delete searchResult?.value[key];
        }
      } else {
        searchConversationResult.value = res?.data;
        option?.cursor
          ? (searchConversationMessageList.value = [
              ...searchConversationMessageList.value,
              ...(res?.data?.searchResultList[0]?.messageList as IMessageModel[]),
            ])
          : (searchConversationMessageList.value = res?.data?.searchResultList[0]?.messageList);
        searchConversationMessageTotalCount.value = res?.data?.totalCount;
        if (
          props?.searchType === 'conversation'
          && (currentSearchTabKey.value === 'fileMessage'
          || currentSearchTabKey.value === 'imageMessage')
        ) {
          searchConversationResultGroupByDate.value = groupResultListByDate(
            searchConversationMessageList.value,
          );
        } else {
          searchConversationResultGroupByDate.value = [];
        }
      }
      isLoading.value = false;
      isSearchDetailLoading.value = false;
    });
};

const setMessageSearchResultListDebounce = debounce(setMessageSearchResultList, 500);

const resetSearchResult = () => {
  searchResult.value = {};
  searchConversationResult.value = {} as ISearchCloudMessageResult;
  searchConversationMessageList.value = [];
  searchConversationResultGroupByDate.value = [];
};

watch(
  () => [keywordList.value, currentSearchTabKey.value, timePosition.value, timePeriod.value],
  (newValue, oldValue) => {
    if (newValue === oldValue) {
      return;
    }
    // Global search must have keywords, but search in conversation can be without keywords
    if (!keywordList?.value?.length && props?.searchType === 'global') {
      resetSearchResult();
      return;
    }
    isLoading.value = true;
    if (props.searchType === 'conversation') {
      resetSearchResult();
      setMessageSearchResultList({
        conversationID: currentSearchConversationID.value,
      });
    } else {
      if (oldValue && oldValue[1] === 'all' && newValue && newValue[1] === 'allMessage') {
        searchResult?.value['allMessage']?.list
        && (searchResult.value['allMessage'].list = searchAllMessageList?.value);
        Object?.keys(searchResult?.value)?.forEach((key: string) => {
          if (key !== 'allMessage') {
            delete searchResult?.value[key];
          }
        });
        isLoading.value = false;
        return;
      } else {
        isResultDetailShow.value = false;
        resetSearchResult();
      }
      setMessageSearchResultListDebounce();
    }
  },
  { immediate: true },
);

const getMoreResult = (result: { key: string; label: string; list: ISearchResultListItem[]; cursor: string | null }) => {
  if (currentSearchTabKey.value === 'all') {
    // View more at this time: Switch to the result corresponding to the corresponding result to display the full search results of its type
    TUIStore.update(StoreName.SEARCH, 'currentSearchMessageType', {
      value: searchMessageTypeList[result.key],
      searchType: props.searchType,
    });
  } else {
    // View more results for a single category: Use the cursor as the search start position to pull the next part of the results
    setMessageSearchResultList({ cursor: result?.cursor || undefined });
  }
};

const getMoreResultInConversation = () => {
  setMessageSearchResultList({
    cursor: searchConversationResult?.value?.cursor,
    conversationID: currentSearchConversationID?.value,
  });
};

const showResultDetail = (isShow: boolean, targetType?: string, targetResult?: IMessageModel | ISearchResultListItem) => {
  isResultDetailShow.value = isShow;
  if (targetType) {
    TUIStore.update(StoreName.SEARCH, 'currentSearchMessageType', {
      value: searchMessageTypeList[targetType],
      searchType: props.searchType,
    });
  }
  currentSearchConversationID.value = (targetResult as ISearchResultListItem)?.conversation?.conversationID || '';
  searchConversationMessageTotalCount.value = (targetResult as ISearchResultListItem)?.messageCount;
  if (targetResult) {
    isSearchDetailLoading.value = true;
    setMessageSearchResultListDebounce({
      conversationID: currentSearchConversationID.value,
    });
  }
};

const generateListItemClass = (item: ISearchResultListItem): string[] => {
  return currentSearchConversationID.value === item?.conversation?.conversationID
    ? ['list-item', 'list-item-selected']
    : ['list-item'];
};

const generateResultItemDisplayType = (): string => {
  if (props.searchType === 'conversation' && currentSearchTabKey.value === 'fileMessage') {
    return 'file';
  } else if (props.searchType === 'conversation' && currentSearchTabKey.value === 'imageMessage') {
    return 'image';
  } else if (isPC) {
    return 'bubble';
  } else {
    return 'info';
  }
};

const groupResultListByDate = (
  messageList: IMessageModel[],
): Array<{ date: string; list: IMessageModel[] }> => {
  const result: Array<{ date: string; list: IMessageModel[] }> = [];
  if (!messageList?.length) {
    return result;
  } else if (messageList?.length === 1) {
    result.push({ date: generateSearchResultYMD(messageList[0]?.time), list: messageList });
    return result;
  }
  let prevYMD = '';
  let currYMD = '';
  for (let i = 0; i < messageList?.length; i++) {
    currYMD = generateSearchResultYMD(messageList[i]?.time);
    if (prevYMD !== currYMD) {
      result.push({ date: currYMD, list: [messageList[i]] });
    } else {
      result[result?.length - 1]?.list?.push(messageList[i]);
    }
    prevYMD = currYMD;
  }
  return result;
};

const navigateToChatPosition = (message: IMessageModel) => {
  if (props.searchType === 'global') {
    // Global search, close the search container
    TUIStore.update(StoreName.SEARCH, 'currentSearchingStatus', {
      isSearching: false,
      searchType: props.searchType,
    });
    // switch conversation
    TUIConversationService.switchConversation(message?.conversationID).then(() => {
      TUIStore.update(StoreName.CHAT, 'messageSource', message);
      isUniFrameWork && TUIGlobal?.navigateTo({
        url: '/TUIKit/components/TUIChat/index',
      });
    });
  } else if (props.searchType === 'conversation') {
    // Search in conversation, close the search container
    TUIStore.update(StoreName.SEARCH, 'isShowInConversationSearch', false);
    TUIStore.update(StoreName.CHAT, 'messageSource', message);
    isUniFrameWork && TUIGlobal?.navigateBack();
  }
};

const generateVueRenderKey = (value: string): string => {
  return `${currentSearchTabKey}-${value}`;
};

return (_ctx: any,_cache: any) => {
  return (_unref(isLoading))
    ? (_openBlock(), _createBlock(SearchResultLoading, {
        key: 0,
        class: _normalizeClass(['search-result-loading', !_unref(isPC) && 'search-result-loading-h5'])
      }, null, 8, ["class"]))
    : (_unref(isSearchDefaultShow))
      ? (_openBlock(), _createBlock(SearchResultDefault, {
          key: 1,
          class: _normalizeClass(['search-result-default', !_unref(isPC) && 'search-result-default-h5'])
        }, null, 8, ["class"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass([
      'tui-search-result',
      !_unref(isPC) && 'tui-search-result-h5',
      _unref(isPC) && _unref(isResultDetailShow) && 'tui-search-result-with-border',
    ])
        }, [
          (props.searchType !== 'conversation' && (_unref(isPC) || !_unref(isResultDetailShow)))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searchResult), (result) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: result.key,
                      class: "tui-search-result-list-item"
                    }, [
                      (props.searchType === 'global')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(TUITranslateService).t(`TUISearch.${result.label}`)), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(result.list, (item) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: item.conversation.conversationID,
                            class: _normalizeClass([generateListItemClass(item)])
                          }, [
                            (result.key === 'contact' || result.key === 'group' || item.conversation)
                              ? (_openBlock(), _createBlock(SearchResultItem, {
                                  key: 0,
                                  listItem: item,
                                  type: result.key,
                                  displayType: "info",
                                  keywordList: _unref(keywordList),
                                  onShowResultDetail: showResultDetail,
                                  onNavigateToChatPosition: navigateToChatPosition
                                }, null, 8, ["listItem", "type", "keywordList"]))
                              : _createCommentVNode("", true)
                          ], 2))
                        }), 128))
                      ]),
                      (_unref(currentSearchTabKey) === 'all' || result.cursor)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "more",
                            onClick: ($event: any) => (getMoreResult(result))
                          }, [
                            _createVNode(Icon, {
                              class: "more-icon",
                              file: _unref(searchIcon),
                              width: "12px",
                              height: "12px"
                            }, null, 8, ["file"]),
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("span", null, _toDisplayString(_unref(TUITranslateService).t("TUISearch.查看更多")), 1),
                              _createElementVNode("span", null, _toDisplayString(_unref(TUITranslateService).t(`TUISearch.${result.label}`)), 1)
                            ])
                          ], 8, _hoisted_5))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(isResultDetailShow) || props.searchType === 'conversation')
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([
        'tui-search-result-detail',
        props.searchType === 'conversation' && 'tui-search-result-in-conversation',
      ])
              }, [
                (_unref(isSearchDetailLoading))
                  ? (_openBlock(), _createBlock(SearchResultLoading, {
                      key: 0,
                      class: _normalizeClass(['search-result-loading', !_unref(isPC) && 'search-result-loading-h5'])
                    }, null, 8, ["class"]))
                  : _createCommentVNode("", true),
                (!_unref(isSearchDetailLoading) && _unref(isResultDetailShow) && props.searchType !== 'conversation')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("span", null, _toDisplayString(_unref(searchConversationMessageTotalCount)), 1),
                          _createElementVNode("span", null, _toDisplayString(_unref(TUITranslateService).t("TUISearch.条与")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(keywordList), (keyword, index) => {
                            return (_openBlock(), _createElementBlock("span", { key: index }, [
                              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "normal" }, "\"", -1)),
                              _createElementVNode("span", _hoisted_11, _toDisplayString(keyword), 1),
                              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "normal" }, "\"", -1))
                            ]))
                          }), 128))
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("span", null, _toDisplayString(_unref(TUITranslateService).t("TUISearch.相关的")), 1),
                          _createElementVNode("span", null, _toDisplayString(_unref(TUITranslateService).t(
                `TUISearch.${_unref(currentSearchTabKey) === "allMessage"
                  ? "结果"
                  : _unref(searchMessageTypeList)[_unref(currentSearchTabKey)].label
                }`
              )), 1)
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: "header-enter",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(enterConversation)({ conversationID: _unref(currentSearchConversationID) })))
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_unref(TUITranslateService).t("TUISearch.进入聊天")), 1),
                        _createVNode(Icon, {
                          class: "enter-icon",
                          file: _unref(enterIcon),
                          width: "14px",
                          height: "14px"
                        }, null, 8, ["file"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (!_unref(isSearchDetailLoading) &&
          _unref(searchConversationMessageList) &&
          _unref(searchConversationMessageList)[0]
        )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      (props.searchType === 'global' ||
            (_unref(currentSearchTabKey) !== 'imageMessage' && _unref(currentSearchTabKey) !== 'fileMessage')
          )
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(searchConversationMessageList), (item) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: generateVueRenderKey(item.ID),
                              class: _normalizeClass(['list-item'])
                            }, [
                              _createVNode(SearchResultItem, {
                                listItem: item,
                                listItemContent: item.getMessageContent(),
                                type: _unref(currentSearchTabKey),
                                displayType: generateResultItemDisplayType(),
                                keywordList: _unref(keywordList),
                                onShowResultDetail: showResultDetail,
                                onNavigateToChatPosition: navigateToChatPosition
                              }, null, 8, ["listItem", "listItemContent", "type", "displayType", "keywordList"])
                            ]))
                          }), 128))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(searchConversationResultGroupByDate), (group) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: generateVueRenderKey(group.date),
                              class: _normalizeClass(['list-group', _unref(currentSearchTabKey) === 'fileMessage'? 'list-group-file' : 'list-group-image'])
                            }, [
                              _createElementVNode("div", _hoisted_14, _toDisplayString(group.date), 1),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.list, (item) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: generateVueRenderKey(item.ID),
                                  class: _normalizeClass(['list-group-item'])
                                }, [
                                  _createVNode(SearchResultItem, {
                                    listItem: item,
                                    listItemContent: item.getMessageContent(),
                                    type: _unref(currentSearchTabKey),
                                    displayType: generateResultItemDisplayType(),
                                    keywordList: _unref(keywordList),
                                    onShowResultDetail: showResultDetail,
                                    onNavigateToChatPosition: navigateToChatPosition
                                  }, null, 8, ["listItem", "listItemContent", "type", "displayType", "keywordList"])
                                ]))
                              }), 128))
                            ], 2))
                          }), 128)),
                      (_unref(searchConversationResult) && _unref(searchConversationResult).cursor)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            class: "more",
                            onClick: getMoreResultInConversation
                          }, [
                            _createVNode(Icon, {
                              class: "more-icon",
                              file: _unref(searchIcon),
                              width: "12px",
                              height: "12px"
                            }, null, 8, ["file"]),
                            _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(TUITranslateService).t("TUISearch.查看更多历史记录")), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true)
        ], 2))
}
}

})