import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "record-title" }
const _hoisted_2 = { class: "record-abstract-container" }
const _hoisted_3 = { class: "record-footer" }

import { ref, withDefaults } from '../../../../../adapter-vue';
import { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import Overlay from '../../../../common/Overlay/index.vue';
import Drawer from '../../../../common/Drawer/index.vue';
import SimpleMessageList from '../simple-message-list/index.vue';
import { isH5, isPC, isUniFrameWork } from '../../../../../utils/env';
import { transformTextWithKeysToEmojiNames } from '../../../emoji-config/index';
import { IMergeMessageContent } from '../../../../../interface';

interface IEmits {
  (e: 'assignMessageIDInUniapp', messageID: string): void;
}

interface IProps {
  // Core data for rendering message record card and message list
  renderData: IMergeMessageContent;
  /**
   * The MessageRecord component has two main functions:
   * 1. display message record cards primarily.
   * 2. clicking on it and show the simple message list.
   * When used as a nested component with the disabled prop
   * it is only need renderData to render message record cards.
   * Therefore, 'messageItem' and 'disabled' is not a required prop.
   */
  disabled?: boolean;
  messageItem?: IMessageModel;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    renderData: {},
    disabled: { type: Boolean, default: false },
    messageItem: { default: () => ({}) as IMessageModel }
  },
  emits: ["assignMessageIDInUniapp"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;
const props = __props;

const isMessageListVisible = ref(false);

function openMergeDetail() {
  if (props.disabled) {
    return;
  }
  if (!isUniFrameWork) {
    isMessageListVisible.value = true;
  } else {
    emits('assignMessageIDInUniapp', props.messageItem.ID);
  }
}

function closeMergeDetail() {
  isMessageListVisible.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "message-record-container",
      onClick: openMergeDetail
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(props.renderData.title), 1),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.renderData.abstractList.slice(0, 7), (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "record-abstract-item"
          }, _toDisplayString(_unref(transformTextWithKeysToEmojiNames)(item)), 1))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(TUITranslateService).t('TUIChat.聊天记录')), 1)
    ]),
    (!props.disabled && _unref(isPC))
      ? (_openBlock(), _createBlock(Overlay, {
          key: 0,
          visible: _unref(isMessageListVisible),
          onOnOverlayClick: _cache[0] || (_cache[0] = ($event: any) => (isMessageListVisible.value = false))
        }, {
          default: _withCtx(() => [
            _createVNode(SimpleMessageList, {
              isMounted: _unref(isMessageListVisible),
              renderData: props.renderData,
              messageID: props.messageItem.ID,
              onCloseOverlay: closeMergeDetail
            }, null, 8, ["isMounted", "renderData", "messageID"])
          ]),
          _: 1
        }, 8, ["visible"]))
      : (!props.disabled && _unref(isH5) && !_unref(isUniFrameWork))
        ? (_openBlock(), _createBlock(Drawer, {
            key: 1,
            visible: _unref(isMessageListVisible),
            isFullScreen: true,
            overlayColor: 'transparent',
            popDirection: 'right'
          }, {
            default: _withCtx(() => [
              _createVNode(SimpleMessageList, {
                isMounted: _unref(isMessageListVisible),
                renderData: props.renderData,
                messageID: props.messageItem.ID,
                onCloseOverlay: closeMergeDetail
              }, null, 8, ["isMounted", "renderData", "messageID"])
            ]),
            _: 1
          }, 8, ["visible"]))
        : _createCommentVNode("", true)
  ]))
}
}

})