import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['message-abstract-image-container'])
}
const _hoisted_2 = ["src"]

import { withDefaults, computed } from '../../../../../adapter-vue';
import { IImageMessageContent } from '../../../../../interface';
interface IProps {
  messageContent: Record<string, unknown> | IImageMessageContent | undefined;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'message-abstract-image',
  props: {
    messageContent: { default: () => ({}) as IImageMessageContent }
  },
  setup(__props: any) {

const props = __props;

const imageUrl = computed<string>(() => (props.messageContent as IImageMessageContent).url || '');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: _normalizeClass(['message-abstract-image']),
      src: _unref(imageUrl)
    }, null, 8, _hoisted_2)
  ]))
}
}

})