import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, watchEffect } from '../../../adapter-vue';
import { isWeChat } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  show: {
    type: Boolean,
    default: () => false,
  },
},
  emits: ['update:show'],
  setup(__props, { emit: __emit }) {

const props = __props;

const showMask = ref(false);

watchEffect(() => {
  showMask.value = props.show;
});

const emit = __emit;

const toggleView = () => {
  showMask.value = !showMask.value;
  emit('update:show', showMask.value);
};


return (_ctx: any,_cache: any) => {
  return (_unref(showMask))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "mask",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (!_unref(isWeChat) && toggleView), ["self"]))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : _createCommentVNode("", true)
}
}

})