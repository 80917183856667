import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

import { withDefaults } from '../../../../adapter-vue';
import { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import Icon from '../../../common/Icon.vue';
import files from '../../../../assets/icon/file-light.svg';
import type { IFileMessageContent } from '../../../../interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'message-file',
  props: {
    content: { default: () => ({} as IFileMessageContent) },
    messageItem: { default: () => ({} as IMessageModel) }
  },
  setup(__props: any) {

const props = __props;

const download = () => {
  if (props.messageItem.hasRiskContent) {
    return;
  }
  const option = {
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  } as RequestInit;
  // If the browser supports fetch, use blob to download, so as to avoid the browser clicking the a tag and jumping to the preview of the new page
  if ((window as any)?.fetch) {
    fetch(props.content.url, option)
      .then(res => res.blob())
      .then((blob) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = props.content.name;
        a.click();
      });
  } else {
    const a = document.createElement('a');
    a.href = props.content.url;
    a.target = '_blank';
    a.download = props.content.name;
    a.click();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "file-message-montainer",
    title: _unref(TUITranslateService).t('TUIChat.单击下载'),
    onClick: download
  }, [
    _createVNode(Icon, {
      file: _unref(files),
      class: "file-icon"
    }, null, 8, ["file"]),
    _createElementVNode("div", null, [
      _createElementVNode("div", null, _toDisplayString(props.content.name), 1),
      _createElementVNode("div", null, _toDisplayString(props.content.size), 1)
    ])
  ], 8, _hoisted_1))
}
}

})