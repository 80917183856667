import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message-input-wrapper" }

import {
  TUIStore,
  StoreName,
  IConversationModel,
} from '@tencentcloud/chat-uikit-engine';
import { ref } from '../../../adapter-vue';
import MessageInputEditor from './message-input-editor.vue';
import MessageInputAt from './message-input-at/index.vue';
import MessageInputButton from './message-input-button.vue';
import MessageInputQuote from './message-input-quote/index.vue';
import { sendMessages, sendTyping } from '../utils/sendMessage';
import { transformTextWithEmojiNamesToKeys } from '../emoji-config';
import { isPC, isH5 } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  placeholder: {
    type: String,
    default: 'this is placeholder',
  },
  isMuted: {
    type: Boolean,
    default: true,
  },
  muteText: {
    type: String,
    default: '',
  },
  enableInput: {
    type: Boolean,
    default: true,
  },
  enableAt: {
    type: Boolean,
    default: true,
  },
  enableDragUpload: {
    type: Boolean,
    default: true,
  },
  enableTyping: {
    type: Boolean,
    default: true,
  },
},
  emits: ['sendMessage', 'resetReplyOrReference', 'onTyping'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;
const editor = ref<InstanceType<typeof MessageInputEditor>>();
const messageInputAtRef = ref<InstanceType<typeof MessageInputAt>>();
const currentConversation = ref<IConversationModel>();

TUIStore.watch(StoreName.CONV, {
  currentConversation: (conversation: IConversationModel) => {
    currentConversation.value = conversation;
  },
});

const onTyping = (inputContentEmpty: boolean, inputBlur: boolean) => {
  sendTyping(inputContentEmpty, inputBlur);
};

const onAt = (show: boolean) => {
  messageInputAtRef.value?.toggleAtList(show);
};

const sendMessage = async () => {
  const _editorContentList = editor.value?.getEditorContent();
  if (!_editorContentList || !currentConversation.value) return;
  const editorContentList = _editorContentList.map((editor: any) => {
    if (editor.type === 'text') {
      editor.payload.text = transformTextWithEmojiNamesToKeys(editor.payload.text);
    }
    return editor;
  });
  await sendMessages(
    editorContentList,
    currentConversation.value,
  );
  emit('sendMessage');
  editor.value?.resetEditor();
};

const insertEmoji = (emoji: any) => {
  editor.value?.addEmoji(emoji);
};

const onAtListOpen = () => {
  if (isH5) {
    editor.value?.blur();
  }
};

const insertAt = (atInfo: any) => {
  editor?.value?.insertAt && editor?.value?.insertAt(atInfo);
};

const reEdit = (content: any) => {
  editor.value?.resetEditor();
  editor.value?.setEditorContent(content);
};

__expose({
  insertEmoji,
  reEdit,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['message-input-container', !_unref(isPC) && 'message-input-container-h5'])
    }, [
      _createVNode(MessageInputEditor, {
        ref_key: "editor",
        ref: editor,
        placeholder: props.placeholder,
        isMuted: props.isMuted,
        muteText: props.muteText,
        enableInput: props.enableInput,
        enableAt: props.enableAt,
        enableTyping: props.enableTyping,
        enableDragUpload: props.enableDragUpload,
        onSendMessage: sendMessage,
        onOnTyping: onTyping,
        onOnAt: onAt
      }, null, 8, ["placeholder", "isMuted", "muteText", "enableInput", "enableAt", "enableTyping", "enableDragUpload"]),
      (!props.isMuted)
        ? (_openBlock(), _createBlock(MessageInputButton, {
            key: 0,
            onSendMessage: sendMessage
          }))
        : _createCommentVNode("", true),
      (props.enableAt)
        ? (_openBlock(), _createBlock(MessageInputAt, {
            key: 1,
            ref_key: "messageInputAtRef",
            ref: messageInputAtRef,
            onInsertAt: insertAt,
            onOnAtListOpen: onAtListOpen
          }, null, 512))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(MessageInputQuote)
  ]))
}
}

})