import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dialog-main-header"
}
const _hoisted_2 = { class: "dialog-main-title" }
const _hoisted_3 = {
  key: 1,
  class: "dialog-main-footer"
}

import { ref, watchEffect } from '../../../adapter-vue';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { isPC, isH5, isUniFrameWork } from '../../../utils/env';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  show: {
    type: Boolean,
    default: false,
  },
  isHeaderShow: {
    type: Boolean,
    default: true,
  },
  isFooterShow: {
    type: Boolean,
    default: true,
  },
  background: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: '',
  },
  center: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:show', 'submit'],
  setup(__props, { emit: __emit }) {

const clickType = {
  OUTSIDE: 'outside',
  INSIDE: 'inside',
};
const props = __props;

const showDialog = ref(false);
const isHeaderShowDialog = ref(true);
const isFooterShowDialog = ref(true);
const backgroundDialog = ref(true);
const showTitle = ref('');

watchEffect(() => {
  showDialog.value = props.show;
  showTitle.value = props.title;
  isHeaderShowDialog.value = props.isHeaderShow;
  isFooterShowDialog.value = props.isFooterShow;
  backgroundDialog.value = props.background;
});

const emit = __emit;

const toggleView = (type: string) => {
  if (type === clickType.OUTSIDE) {
    close();
  }
};

const close = () => {
  showDialog.value = !showDialog.value;
  emit('update:show', showDialog.value);
};

const submit = () => {
  emit('submit');
  close();
};

return (_ctx: any,_cache: any) => {
  return (_unref(showDialog))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["dialog", [!_unref(isPC) ? 'dialog-h5' : '', __props.center ? 'center' : '']]),
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (toggleView(clickType.OUTSIDE)), ["stop","prevent"]))
      }, [
        _createElementVNode("main", {
          class: _normalizeClass(["dialog-main", [!_unref(backgroundDialog) ? 'dialog-main-back' : '']]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (toggleView(clickType.INSIDE)), ["stop","prevent"]))
        }, [
          (_unref(isHeaderShowDialog))
            ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
                _createElementVNode("h1", _hoisted_2, _toDisplayString(_unref(showTitle)), 1),
                _createElementVNode("i", {
                  class: "icon icon-close",
                  onClick: close
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["dialog-main-content", [_unref(isUniFrameWork) && _unref(isH5) ? 'dialog-main-content-uniapp' : '']])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2),
          (_unref(isFooterShowDialog))
            ? (_openBlock(), _createElementBlock("footer", _hoisted_3, [
                _createElementVNode("button", {
                  class: "btn btn-cancel",
                  onClick: close
                }, _toDisplayString(_unref(TUITranslateService).t('component.取消')), 1),
                _createElementVNode("button", {
                  class: "btn btn-default",
                  onClick: submit
                }, _toDisplayString(_unref(TUITranslateService).t('component.确定')), 1)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}
}

})