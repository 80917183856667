import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "call-content" }

import TUICore, { TUIConstants } from '@tencentcloud/tui-core';
import TUIChatEngine from '@tencentcloud/chat-uikit-engine';
import { computed, ref } from '../../../adapter-vue';
import { JSONToObject } from '../../../utils/index';
import Icon from '../../../components/common/Icon.vue';
import callVideoSVG from '../../../assets/icon/call-video.svg';
import callVoiceSVG from '../../../assets/icon/call-voice.svg';
import OfflinePushInfoManager, { PUSH_SCENE } from '../../../components/TUIChat/offlinePushInfoManager/index';

export default /*@__PURE__*/_defineComponent({
  __name: 'message-call-c2c',
  props: {
  message: {
    type: Object,
    default: () => ({}),
  },
  signalingInfo: {
    type: Object,
    default: () => ({}),
  },
  customContent: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props) {

const props = __props;
const TYPES = ref(TUIChatEngine.TYPES);
const isCallMessage = computed(() => props.signalingInfo != null);
const callInfo = computed((): { type: number; icon: string } => {
  const callType = JSONToObject(props.signalingInfo?.data)?.call_type;
  switch (callType) {
    case 1:
      return {
        type: 1,
        icon: callVoiceSVG,
      };
    case 2:
      return {
        type: 2,
        icon: callVideoSVG,
      };
    default:
      break;
  }
  return {
    type: 0,
    icon: '',
  };
});
const conversationType = computed(() => props.message?.conversationType);
const custom = computed(() => props.customContent?.custom);

const callAgain = () => {
  if (conversationType.value === TUIChatEngine.TYPES.CONV_C2C) {
    const userID = props.message?.flow === 'out' ? props.message?.to : props.message?.from;
    TUICore.callService({
      serviceName: TUIConstants.TUICalling.SERVICE.NAME,
      method: TUIConstants.TUICalling.SERVICE.METHOD.START_CALL,
      params: {
        userIDList: [userID],
        type: callInfo?.value?.type,
        callParams: {
          offlinePushInfo: OfflinePushInfoManager.getOfflinePushInfo(PUSH_SCENE.CALL),
        },
      },
    });
  }
};

return (_ctx: any,_cache: any) => {
  return (_unref(isCallMessage) && _unref(conversationType) === _unref(TYPES).CONV_C2C)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["call", ['call-' + _unref(conversationType), __props.message.flow === 'out' && 'call-reverse']]),
        onClick: callAgain
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['icon', __props.message.flow === 'out' && _unref(callInfo).type === 2 && 'icon-reverse'])
        }, [
          _createVNode(Icon, {
            file: _unref(callInfo).icon
          }, null, 8, ["file"])
        ], 2),
        _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(custom)), 1)
      ], 2))
    : _createCommentVNode("", true)
}
}

})