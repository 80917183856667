import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "tui-contact-list-card-left" }
const _hoisted_2 = { class: "tui-contact-list-card-main" }
const _hoisted_3 = { class: "tui-contact-list-card-main-name" }
const _hoisted_4 = {
  key: 0,
  class: "tui-contact-list-card-main-other"
}
const _hoisted_5 = { class: "tui-contact-list-card-right" }
const _hoisted_6 = {
  key: 0,
  class: "tui-contact-list-card-right-group-type"
}
const _hoisted_7 = {
  key: 1,
  class: "tui-contact-list-card-right-application"
}
const _hoisted_8 = {
  key: 0,
  class: "tui-contact-list-card-right-application-text"
}

import { computed, withDefaults, inject, watch, ref, Ref } from '../../../../adapter-vue';
import TUIChatEngine, {
  TUITranslateService,
  IGroupModel,
  FriendApplication,
  Friend,
} from '@tencentcloud/chat-uikit-engine';
import { IContactInfoType, IUserStatus } from '../../../../interface';
import Avatar from '../../../common/Avatar/index.vue';
import { generateAvatar, generateName, acceptFriendApplication } from '../../utils';
import { isPC } from '../../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    item: { default: () => ({} as IContactInfoType) },
    displayOnlineStatus: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;
const userOnlineStatusMap = inject<Ref<Map<string, IUserStatus>>>('userOnlineStatusMap');
const isOnline = ref<boolean>(false);

const groupType = {
  [TUIChatEngine.TYPES.GRP_WORK]: 'Work',
  [TUIChatEngine.TYPES.GRP_AVCHATROOM]: 'AVChatRoom',
  [TUIChatEngine.TYPES.GRP_PUBLIC]: 'Public',
  [TUIChatEngine.TYPES.GRP_MEETING]: 'Meeting',
  [TUIChatEngine.TYPES.GRP_COMMUNITY]: 'Community',
};

const otherContentForSow = computed((): string => {
  let content = '';
  if (
    (props.item as FriendApplication)?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_TO_ME
    || (props.item as FriendApplication)?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_BY_ME
  ) {
    content = (props.item as FriendApplication)?.wording || '';
  } else if ((props.item as IGroupModel)?.groupID) {
    content = `ID:${(props.item as IGroupModel)?.groupID}`;
  }
  return content;
});

const groupTypeForShow = computed((): string => {
  let type = '';
  if ((props.item as IGroupModel)?.groupID) {
    type = groupType[(props.item as IGroupModel)?.type];
  }
  return type;
});

const showApplicationStatus = computed(() => {
  if (
    (props.item as FriendApplication)?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_BY_ME
  ) {
    return {
      style: 'text',
      label: '等待验证',
    };
  } else if (
    (props.item as FriendApplication)?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_TO_ME
  ) {
    return {
      style: 'button',
      label: '同意',
      onClick: () => {
        acceptFriendApplication((props.item as FriendApplication)?.userID);
      },
    };
  }
  return false;
});

watch(
  () => userOnlineStatusMap?.value,
  () => {
    isOnline.value = getOnlineStatus();
  },
  {
    immediate: true,
    deep: true,
  },
);

function getOnlineStatus(): boolean {
  return !!(
    props.displayOnlineStatus
    && userOnlineStatusMap?.value
    && (props.item as Friend)?.userID
    && userOnlineStatusMap.value?.[(props.item as Friend).userID]?.statusType === TUIChatEngine.TYPES.USER_STATUS_ONLINE
  );
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['tui-contact-list-card', !_unref(isPC) && 'tui-contact-list-card-h5'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(Avatar, {
        class: "tui-contact-list-card-left-avatar",
        useSkeletonAnimation: "",
        url: _unref(generateAvatar)(props.item)
      }, null, 8, ["url"]),
      (props.displayOnlineStatus && props.item)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass({
          'online-status': true,
          'online-status-online': _unref(isOnline),
          'online-status-offline': !_unref(isOnline),
        })
          }, null, 2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(generateName)(props.item)), 1),
      (_unref(otherContentForSow))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(otherContentForSow)), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_unref(groupTypeForShow))
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(groupTypeForShow)), 1))
        : _createCommentVNode("", true),
      (_unref(showApplicationStatus))
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_unref(showApplicationStatus).style === 'text')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_unref(TUITranslateService).t(`TUIContact.${_unref(showApplicationStatus).label}`)), 1))
              : (_unref(showApplicationStatus).style === 'button')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "tui-contact-list-card-right-application-button",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_unref(showApplicationStatus).onClick && _unref(showApplicationStatus).onClick(...args)), ["stop"]))
                  }, _toDisplayString(_unref(TUITranslateService).t(`TUIContact.${_unref(showApplicationStatus).label}`)), 1))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})