import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

import { ref, toRefs } from '../../../adapter-vue';
import { isUniFrameWork } from '../../../utils/env';

interface IProps {
  url: string;
  size?: string;
  borderRadius?: string;
  useSkeletonAnimation?: boolean;
}

interface IEmits {
  (key: 'onLoad', e: Event): void;
  (key: 'onError', e: Event): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    url: { default: 'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png' },
    size: { default: '36px' },
    borderRadius: { default: '5px' },
    useSkeletonAnimation: { type: Boolean, default: false }
  },
  emits: ["onLoad", "onError"],
  setup(__props: any, { emit: __emit }) {

const defaultAvatarUrl = ref('https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png');
const emits = __emit;
const props = __props;

const {
  size: avatarSize,
  url: avatarImageUrl,
  borderRadius: avatarBorderRadius,
  useSkeletonAnimation: useAvatarSkeletonAnimation,
} = toRefs(props);

let reloadAvatarTime = 0;
const isImgLoaded = ref<boolean>(false);
const loadErrorInUniapp = ref<boolean>(false);

function avatarLoadSuccess(e: Event) {
  isImgLoaded.value = true;
  emits('onLoad', e);
}

function avatarLoadFailed(e: Event) {
  reloadAvatarTime += 1;
  if (reloadAvatarTime > 3) {
    return;
  }
  if (isUniFrameWork) {
    loadErrorInUniapp.value = true;
  } else {
    (e.currentTarget as HTMLImageElement).src = defaultAvatarUrl.value;
  }
  emits('onError', e);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "avatar-container",
    style: _normalizeStyle({
      width: _unref(avatarSize),
      height: _unref(avatarSize),
      borderRadius: _unref(avatarBorderRadius),
    })
  }, [
    (_unref(isUniFrameWork))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!_unref(loadErrorInUniapp))
            ? (_openBlock(), _createElementBlock("image", {
                key: 0,
                class: "avatar-image",
                src: _unref(avatarImageUrl) || _unref(defaultAvatarUrl),
                onLoad: avatarLoadSuccess,
                onError: avatarLoadFailed
              }, null, 40, _hoisted_1))
            : (_openBlock(), _createElementBlock("image", {
                key: 1,
                class: "avatar-image",
                src: _unref(defaultAvatarUrl),
                onLoad: avatarLoadSuccess,
                onError: avatarLoadFailed
              }, null, 40, _hoisted_2))
        ], 64))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "avatar-image",
          src: _unref(avatarImageUrl) || _unref(defaultAvatarUrl),
          onLoad: avatarLoadSuccess,
          onError: avatarLoadFailed
        }, null, 40, _hoisted_3)),
    (_unref(useAvatarSkeletonAnimation) && !_unref(isImgLoaded))
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass({
        placeholder: true,
        hidden: _unref(isImgLoaded),
        'skeleton-animation': _unref(useAvatarSkeletonAnimation)
      })
        }, null, 2))
      : _createCommentVNode("", true)
  ], 4))
}
}

})