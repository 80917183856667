import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "header-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "message-text"
}
const _hoisted_7 = {
  key: 0,
  class: "text"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "message-image"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 2,
  class: "message-video"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["src"]
const _hoisted_14 = ["poster"]
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 3,
  class: "message-audio"
}
const _hoisted_17 = {
  key: 4,
  class: "message-face"
}
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 5,
  class: "message-file"
}
const _hoisted_20 = { key: 6 }
const _hoisted_21 = ["onClickCapture"]
const _hoisted_22 = { key: 8 }

import { computed, ref, watch } from '../../../../../adapter-vue';
import TUIChatEngine, {
  TUIStore,
  TUIChatService,
  TUITranslateService,
} from '@tencentcloud/chat-uikit-engine';
import addIcon from '../../../../../assets/icon/back.svg';
import playIcon from '../../../../../assets/icon/video-play.png';
import Icon from '../../../../common/Icon.vue';
import MessageContainer from './message-container.vue';
import MessageRecord from '../message-record/index.vue';
import { parseTextToRenderArray, DEFAULT_BIG_EMOJI_URL, CUSTOM_BIG_EMOJI_URL } from '../../../emoji-config/index';
import { isMobile, isUniFrameWork } from '../../../../../utils/env';
import { IMergeMessageContent } from '../../../../../interface';

interface IProps {
  /**
   * only use messageID when first render of simple-message-list
   * because the nested simple-message-list do not have corresponding message object
   * need to download message from sdk by constructed message
   * and use downloaded message object to render nested simple-message-list
   */
  messageID?: string;
  isMounted?: boolean;
}

interface IEmits {
  (e: 'closeOverlay'): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    messageID: { default: '' },
    isMounted: { type: Boolean, default: false }
  },
  emits: ["closeOverlay"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;
const props = __props;

const TYPES = TUIChatEngine.TYPES;
const isDownloadOccurError = ref(false);
const messageListStack = ref<IMergeMessageContent[]>([]);
const currentMergeMessageInfo = ref<Partial<IMergeMessageContent>>({
  title: '',
  messageList: [],
});
const simpleMessageListRef = ref<HTMLElement>();

watch(() => messageListStack.value.length, async (newValue) => {
  isDownloadOccurError.value = false;
  if (newValue < 1) {
    return;
  }
  const stackTopMessageInfo = messageListStack.value[messageListStack.value.length - 1];
  if (stackTopMessageInfo.downloadKey && stackTopMessageInfo.messageList.length === 0) {
    try {
      const res = await TUIChatService.downloadMergedMessages({
        payload: stackTopMessageInfo,
        type: TUIChatEngine.TYPES.MSG_MERGER,
      } as any);
      // if download complete message, cover the original message in stack top
      messageListStack.value[messageListStack.value.length - 1] = res.payload;
    } catch (error) {
      isDownloadOccurError.value = true;
    }
  }
  currentMergeMessageInfo.value = messageListStack.value[messageListStack.value.length - 1];
});

watch(() => props.isMounted, (newValue) => {
  // For compatibility with uniapp, use watch to implement onMounted
  if (newValue) {
    if (!props.messageID) {
      throw new Error('messageID is required when first render of simple-message-list.');
    }
    const sdkMessagePayload = TUIStore.getMessageModel(props.messageID).getMessage().payload;
    messageListStack.value = [sdkMessagePayload];
  } else {
    messageListStack.value = [];
  }
}, {
  immediate: true,
});

const isReturn = computed(() => {
  return messageListStack.value.length > 1;
});

const isMergeMessageInfoLoaded = computed(() => {
  return currentMergeMessageInfo.value?.messageList ? currentMergeMessageInfo.value.messageList.length > 0 : false;
});

function entryNextLevel(e, sdkMessage: any) {
  messageListStack.value.push(sdkMessage.messageBody[0].payload);
  e.stopPropagation();
}

function backPreviousLevel() {
  messageListStack.value.pop();
  if (messageListStack.value.length < 1) {
    emits('closeOverlay');
  }
}

function previewVideoInUniapp(url: string) {
  if (isUniFrameWork) {
    const encodedUrl = encodeURIComponent(url);
    uni.navigateTo({
      url: `/TUIKit/components/TUIChat/video-play?videoUrl=${encodedUrl}`,
    });
  }
}

function resolveBigFaceUrl(bigFaceKey: string): string {
  let url = '';
  if (bigFaceKey.indexOf('@custom') > -1) {
    url = CUSTOM_BIG_EMOJI_URL + bigFaceKey;
  } else {
    url = DEFAULT_BIG_EMOJI_URL + bigFaceKey;
    if (url.indexOf('@2x') === -1) {
      url += '@2x.png';
    } else {
      url += '.png';
    }
  }
  return url;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'simple-message-list-container': true,
      'simple-message-list-container-mobile': _unref(isMobile),
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: "back",
        onClick: backPreviousLevel
      }, [
        _createVNode(Icon, {
          class: "close-icon",
          file: _unref(addIcon),
          size: '18px'
        }, null, 8, ["file"]),
        (_unref(isReturn))
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(TUITranslateService).t('TUIChat.返回')), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(TUITranslateService).t('TUIChat.关闭')), 1))
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(currentMergeMessageInfo).title), 1)
    ]),
    (_unref(isDownloadOccurError))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Load Merge Message Error "))
      : (_unref(isMergeMessageInfoLoaded))
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            ref_key: "simpleMessageListRef",
            ref: simpleMessageListRef,
            class: "message-list"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentMergeMessageInfo).messageList, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.ID,
                class: _normalizeClass({
          'message-item': true,
        })
              }, [
                _createVNode(MessageContainer, {
                  sender: item.nick,
                  avatar: item.avatar,
                  type: item.messageBody[0].type,
                  time: item.time
                }, {
                  default: _withCtx(() => [
                    (item.messageBody[0].type === _unref(TYPES).MSG_TEXT)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(parseTextToRenderArray)(item.messageBody[0].payload['text']), (textInfo, index) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: index,
                              class: "message-text-container"
                            }, [
                              (textInfo.type === 'text')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(textInfo.content), 1))
                                : (_openBlock(), _createElementBlock("img", {
                                    key: 1,
                                    class: "simple-emoji",
                                    src: textInfo.content,
                                    alt: "small-face"
                                  }, null, 8, _hoisted_8))
                            ]))
                          }), 128))
                        ]))
                      : (item.messageBody[0].type === _unref(TYPES).MSG_IMAGE)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createElementVNode("img", {
                              class: "image",
                              src: (item.messageBody[0].payload)['imageInfoArray'][2]['url'],
                              mode: "widthFix",
                              alt: "image"
                            }, null, 8, _hoisted_10)
                          ]))
                        : (item.messageBody[0].type === _unref(TYPES).MSG_VIDEO)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              (_unref(isUniFrameWork))
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    onClick: ($event: any) => (previewVideoInUniapp((item.messageBody[0].payload)['remoteVideoUrl']))
                                  }, [
                                    _createElementVNode("image", {
                                      class: "image",
                                      src: (item.messageBody[0].payload)['thumbUrl'],
                                      mode: "widthFix",
                                      alt: "image"
                                    }, null, 8, _hoisted_13),
                                    _createVNode(Icon, {
                                      class: "video-play-icon",
                                      file: _unref(playIcon)
                                    }, null, 8, ["file"])
                                  ], 8, _hoisted_12))
                                : (_openBlock(), _createElementBlock("video", {
                                    key: 1,
                                    class: "video",
                                    controls: "",
                                    poster: (item.messageBody[0].payload)['thumbUrl']
                                  }, [
                                    _createElementVNode("source", {
                                      src: (item.messageBody[0].payload)['remoteVideoUrl'],
                                      type: "video/mp4"
                                    }, null, 8, _hoisted_15)
                                  ], 8, _hoisted_14))
                            ]))
                          : (item.messageBody[0].type === _unref(TYPES).MSG_AUDIO)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createElementVNode("span", null, _toDisplayString(_unref(TUITranslateService).t("TUIChat.语音")) + " ", 1),
                                _createElementVNode("span", null, _toDisplayString(item.messageBody[0].payload.second) + "s", 1)
                              ]))
                            : (item.messageBody[0].type === _unref(TYPES).MSG_FACE)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                  _createElementVNode("img", {
                                    class: "image",
                                    src: resolveBigFaceUrl(item.messageBody[0].payload.data),
                                    alt: "face"
                                  }, null, 8, _hoisted_18)
                                ]))
                              : (item.messageBody[0].type === _unref(TYPES).MSG_FILE)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_unref(TUITranslateService).t('TUIChat.[文件]')), 1))
                                : (item.messageBody[0].type === _unref(TYPES).MSG_LOCATION)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_unref(TUITranslateService).t('TUIChat.[地理位置]')), 1))
                                  : (item.messageBody[0].type === _unref(TYPES).MSG_MERGER)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 7,
                                        class: "message-merger",
                                        onClickCapture: ($event: any) => (entryNextLevel($event, item))
                                      }, [
                                        _createVNode(MessageRecord, {
                                          disabled: "",
                                          renderData: item.messageBody[0].payload
                                        }, null, 8, ["renderData"])
                                      ], 40, _hoisted_21))
                                    : (item.messageBody[0].type === _unref(TYPES).MSG_CUSTOM)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_unref(TUITranslateService).t('TUIChat.[自定义消息]')), 1))
                                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["sender", "avatar", "type", "time"])
              ]))
            }), 128))
          ], 512))
        : _createCommentVNode("", true)
  ], 2))
}
}

})