import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import {
  TUIStore,
  StoreName,
} from '@tencentcloud/chat-uikit-engine';
import { ref } from '../../adapter-vue';

import CreateGroup from './create-group/index.vue';
import ManageGroup from './manage-group/index.vue';
import SelectMember from './select-member/index.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const isShowCreateGroup = ref(false);
const isShowManageGroup = ref(false);
const isShowSelectMember = ref(false);

TUIStore.watch(StoreName.GRP, {
  isShowCreateComponent: (data: any) => {
    if (data) {
      isShowCreateGroup.value = true;
    } else {
      isShowCreateGroup.value = false;
    }
  },
  isShowManageComponent: (data: any) => {
    if (data) {
      isShowManageGroup.value = true;
    } else {
      isShowManageGroup.value = false;
    }
  },
  isShowSelectComponent: (data: any) => {
    if (data) {
      isShowSelectMember.value = true;
    } else {
      isShowSelectMember.value = false;
    }
  },
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([(_unref(isShowCreateGroup) || _unref(isShowManageGroup) || _unref(isShowSelectMember)) && 'tui-group'])
  }, [
    (_unref(isShowCreateGroup))
      ? (_openBlock(), _createBlock(CreateGroup, { key: 0 }))
      : _createCommentVNode("", true),
    (_unref(isShowManageGroup))
      ? (_openBlock(), _createBlock(ManageGroup, { key: 1 }))
      : _createCommentVNode("", true),
    (_unref(isShowSelectMember))
      ? (_openBlock(), _createBlock(SelectMember, { key: 2 }))
      : _createCommentVNode("", true)
  ], 2))
}
}

})