import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "copyright" }
const _hoisted_2 = { class: "copyright-text" }

import { ref, onMounted, onUnmounted } from '../../../../../adapter-vue';
import {
  TUIStore,
  StoreName,
  IMessageModel,
  TUITranslateService,
} from '@tencentcloud/chat-uikit-engine';
import Icon from '../../../../common/Icon.vue';
import TranslationContent from './translation-content.vue';
import checkIcon from '../../../../../assets/icon/check-sm.svg';
import { ITranslateInfo } from '../../../../../interface';

interface IProps {
  message: IMessageModel;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    message: { default: () => ({} as IMessageModel) }
  },
  setup(__props: any) {

const props = __props;

const translationVisible = ref<boolean>(false);
const hasTranslationError = ref<boolean>(false);

const translationWrapperRef = ref<HTMLDivElement>();

let isSingleTranslation = true;

onMounted(() => {
  TUIStore.watch(StoreName.CHAT, {
    translateTextInfo: onMessageTranslationUpdated,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.CHAT, {
    translateTextInfo: onMessageTranslationUpdated,
  });
});

function toggleErrorStatus(hasError: boolean) {
  hasTranslationError.value = hasError;
}

function onMessageTranslationUpdated(info: Map<string, ITranslateInfo[]>) {
  if (info === undefined) return;
  isSingleTranslation = false;
  const translationInfoList = info.get(props.message.conversationID) || [];
  for (let i = 0; i < translationInfoList.length; ++i) {
    const { messageID, visible } = translationInfoList[i];
    if (messageID === props.message.ID && visible !== undefined) {
      if (translationInfoList.length === 1 && visible) {
        isSingleTranslation = true;
      }
      hasTranslationError.value = false;
      translationVisible.value = visible;
      break;
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_unref(translationVisible))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "translationWrapperRef",
        ref: translationWrapperRef,
        class: _normalizeClass({
      'message-translation': true,
      'reverse': props.message.flow === 'out',
      'error': _unref(hasTranslationError),
    })
      }, [
        _createVNode(TranslationContent, {
          message: props.message,
          translationContentVisible: _unref(translationVisible),
          translationWrapperRef: _unref(translationWrapperRef),
          isSingleTranslation: _unref(isSingleTranslation),
          onToggleErrorStatus: toggleErrorStatus
        }, null, 8, ["message", "translationContentVisible", "translationWrapperRef", "isSingleTranslation"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(Icon, {
            file: _unref(checkIcon),
            size: "13px"
          }, null, 8, ["file"]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(TUITranslateService).t('TUIChat.由IM提供翻译支持')), 1)
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})