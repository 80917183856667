import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import TUIChatEngine, {
  TUIChatService,
  TUIStore,
  StoreName,
  IConversationModel,
  SendMessageParams,
  SendMessageOptions,
} from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import { ref, computed } from '../../../../adapter-vue';
import { isPC, isWeChat, isUniFrameWork } from '../../../../utils/env';
import ToolbarItemContainer from '../toolbar-item-container/index.vue';
import imageIconLight from '../../../../assets/icon/image-light.svg';
import imageIconDark from '../../../../assets/icon/image-dark.svg';
import imageUniIcon from '../../../../assets/icon/image-uni.png';
import cameraUniIcon from '../../../../assets/icon/camera-uni.png';
import { isEnabledMessageReadReceiptGlobal } from '../../utils/utils';
import OfflinePushInfoManager, { IOfflinePushInfoCreateParams } from '../../offlinePushInfoManager/index';
import TUIChatConfig from '../../config';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  // Image source: only valid for uni-app version, web version only supports selecting images from the album.
  // album: Select from album
  // camera: Take a photo using the camera
  imageSourceType: {
    type: String,
    default: 'album',
  },
},
  setup(__props) {

const props = __props;

const inputRef = ref();
const currentConversation = ref<IConversationModel>();
const theme = TUIChatConfig.getTheme();
const IMAGE_TOOLBAR_SHOW_MAP = {
  web_album: {
    icon: theme === 'dark' ? imageIconDark : imageIconLight,
    title: '图片',
  },
  uni_album: {
    icon: imageUniIcon,
    title: '图片',
  },
  uni_camera: {
    icon: cameraUniIcon,
    title: '拍照',
  },
};

TUIStore.watch(StoreName.CONV, {
  currentConversation: (conversation: IConversationModel) => {
    currentConversation.value = conversation;
  },
});

const imageToolbarForShow = computed((): { icon: string; title: string } => {
  if (isUniFrameWork) {
    return props.imageSourceType === 'camera'
      ? IMAGE_TOOLBAR_SHOW_MAP['uni_camera']
      : IMAGE_TOOLBAR_SHOW_MAP['uni_album'];
  } else {
    return IMAGE_TOOLBAR_SHOW_MAP['web_album'];
  }
});

const onIconClick = () => {
  // uni-app send image
  if (isUniFrameWork) {
    if (isWeChat && TUIGlobal?.chooseMedia) {
      TUIGlobal?.chooseMedia({
        count: 1,
        mediaType: ['image'],
        sizeType: ['original', 'compressed'],
        sourceType: [props.imageSourceType], // Use camera or select from album.
        success: function (res: any) {
          sendImageMessage(res);
        },
      });
    } else {
      // uni-app H5/App send image
      TUIGlobal?.chooseImage({
        count: 1,
        sourceType: [props.imageSourceType], // Use camera or select from album.
        success: function (res) {
          sendImageMessage(res);
        },
      });
    }
  } else {
    if (inputRef.value?.click) {
      inputRef.value.click();
    }
  }
};

const sendImageInWeb = (e: any) => {
  if (e?.target?.files?.length <= 0) {
    return;
  }
  sendImageMessage(e?.target);
  e.target.value = '';
};

const sendImageMessage = (files: any) => {
  if (!files) {
    return;
  }
  const options = {
    to:
      currentConversation?.value?.groupProfile?.groupID
      || currentConversation?.value?.userProfile?.userID,
    conversationType: currentConversation?.value?.type,
    payload: {
      file: files,
    },
    needReadReceipt: isEnabledMessageReadReceiptGlobal(),
  } as SendMessageParams;
  const offlinePushInfoCreateParams: IOfflinePushInfoCreateParams = {
    conversation: currentConversation.value,
    payload: options.payload,
    messageType: TUIChatEngine.TYPES.MSG_IMAGE,
  };
  const sendMessageOptions: SendMessageOptions = {
    offlinePushInfo: OfflinePushInfoManager.create(offlinePushInfoCreateParams),
  };
  TUIChatService.sendImageMessage(options, sendMessageOptions);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ToolbarItemContainer, {
    iconFile: _unref(imageToolbarForShow).icon,
    title: _unref(imageToolbarForShow).title,
    iconWidth: _unref(isUniFrameWork) ? '32px' : '20px',
    iconHeight: _unref(isUniFrameWork) ? '25px' : '18px',
    needDialog: false,
    onOnIconClick: onIconClick
  }, {
    default: _withCtx(() => [
      (!_unref(isUniFrameWork))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['image-upload', !_unref(isPC) && 'image-upload-h5'])
          }, [
            _createElementVNode("input", {
              ref_key: "inputRef",
              ref: inputRef,
              title: "图片",
              type: "file",
              "data-type": "image",
              accept: "image/gif,image/jpeg,image/jpg,image/png,image/bmp,image/webp",
              onChange: sendImageInWeb
            }, null, 544)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["iconFile", "title", "iconWidth", "iconHeight"]))
}
}

})