import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "memeber-profile"
}
const _hoisted_2 = { class: "memeber-profile-main" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "list" }
const _hoisted_5 = { class: "memeber-profile-footer" }
const _hoisted_6 = {
  key: 1,
  class: "edit-h5"
}
const _hoisted_7 = { class: "main" }
const _hoisted_8 = { class: "edit-h5-header" }
const _hoisted_9 = { class: "left" }
const _hoisted_10 = { class: "edit-h5-profile" }
const _hoisted_11 = { class: "memeber-profile-main" }
const _hoisted_12 = { class: "list" }
const _hoisted_13 = { class: "memeber-profile-footer" }

import { ref, watch, watchEffect } from '../../../adapter-vue';
import TUIChatEngine, {
  TUITranslateService,
  TUIUserService,
  TUIConversationService,
  TUIFriendService,
  TUIStore,
  StoreName,
} from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import Avatar from '../../common/Avatar/index.vue';
import { IUserProfile } from '../../../interface';
import { isUniFrameWork } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'manage-profile',
  props: {
  userInfo: {
    type: Object,
    default: () => ({}),
  },
},
  emits: [
  'handleSwitchConversation',
  'close',
  'openConversation',
],
  setup(__props, { emit: __emit }) {

const props = __props;

const isFriendShip = ref(false);
const userInfoManager = ref<IUserProfile>({});

watchEffect(() => {
  userInfoManager.value = props.userInfo;
});
const emits = __emit;

watch(
  () => props.userInfo,
  async (newVal: any, oldVal: any) => {
    if (newVal === oldVal) return;
    const res = await TUIUserService.getUserProfile({
      userIDList: [props.userInfo.userID],
    });
    userInfoManager.value = res?.data[0];
    checkFriend();
  },
  {
    deep: true,
    immediate: true,
  },
);

const enter = async (ID: any, type: string) => {
  const name = `${type}${ID}`;
  TUIConversationService.getConversationProfile(name)
    .then((res: any) => {
      TUIConversationService.switchConversation(res.data.conversation.conversationID).then(() => {
        TUIStore.update(StoreName.GRP, 'isShowManageComponent', false);
        if (isUniFrameWork) {
          TUIGlobal?.navigateBack();
        }
      });
    })
    .catch((err: any) => {
      console.warn('获取会话资料失败', err.code, err.msg);
    });
};
const checkFriend = async () => {
  if (!(userInfoManager.value as any).userID) return;
  TUIFriendService.checkFriend({
    userIDList: [userInfoManager.value.userID],
    type: TUIChatEngine.TYPES.SNS_CHECK_TYPE_BOTH,
  }).then((res: any) => {
    const relation = res?.data?.successUserIDList?.[0]?.relation;
    isFriendShip.value = (relation === TUIChatEngine.TYPES.SNS_TYPE_BOTH_WAY);
  });
};

const showEnter = () => {
  return isFriendShip.value || !TUIStore.getData(StoreName.APP, 'isOfficial');
};

const close = (tabName: string) => {
  emits('close', tabName);
};

return (_ctx: any,_cache: any) => {
  return (!_unref(isUniFrameWork))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "avatar",
            src: 
          _unref(userInfoManager).avatar ||
            'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
        ,
            onerror: "this.onerror=null;this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
          }, null, 8, _hoisted_3),
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("h2", null, _toDisplayString(_unref(userInfoManager).nick || _unref(userInfoManager).userID), 1),
            _createElementVNode("li", null, [
              _cache[3] || (_cache[3] = _createElementVNode("label", null, "ID：", -1)),
              _createElementVNode("span", null, _toDisplayString(_unref(userInfoManager).userID), 1)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("label", null, _toDisplayString(_unref(TUITranslateService).t("TUIContact.个性签名")) + "：", 1),
              _createElementVNode("span", null, _toDisplayString(_unref(userInfoManager).selfSignature), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (showEnter())
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (enter(_unref(userInfoManager).userID, 'C2C')))
              }, _toDisplayString(_unref(TUITranslateService).t("TUIContact.发送消息")), 1))
            : _createCommentVNode("", true)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        _createElementVNode("main", _hoisted_7, [
          _createElementVNode("header", _hoisted_8, [
            _createElementVNode("aside", _hoisted_9, [
              _createElementVNode("h1", null, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.群成员`)), 1)
            ]),
            _createElementVNode("span", {
              class: "close",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (close('profile')))
            }, _toDisplayString(_unref(TUITranslateService).t(`关闭`)), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(Avatar, {
                class: "avatar",
                url: _unref(userInfoManager).avatar,
                size: "60px"
              }, null, 8, ["url"]),
              _createElementVNode("ul", _hoisted_12, [
                _createElementVNode("h1", null, _toDisplayString(_unref(userInfoManager).nick || _unref(userInfoManager).userID), 1),
                _createElementVNode("li", null, [
                  _cache[4] || (_cache[4] = _createElementVNode("label", null, "ID：", -1)),
                  _createElementVNode("span", null, _toDisplayString(_unref(userInfoManager).userID), 1)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("label", null, _toDisplayString(_unref(TUITranslateService).t("TUIContact.个性签名")) + "：", 1),
                  _createElementVNode("span", null, _toDisplayString(_unref(userInfoManager).selfSignature), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              (showEnter())
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "button",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (enter(_unref(userInfoManager).userID, 'C2C')))
                  }, _toDisplayString(_unref(TUITranslateService).t("TUIContact.发送消息")), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]))
}
}

})