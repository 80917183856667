import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message-tip" }

import { computed } from '../../../../adapter-vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'message-tip',
  props: {
  content: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props) {

const props = __props;
const tipContent = computed(() => props.content?.text || props.content?.custom || '');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_unref(tipContent)), 1)
  ]))
}
}

})