import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "simple-message-container" }
const _hoisted_2 = { class: "simple-message-avatar" }
const _hoisted_3 = { class: "simple-message-sender" }
const _hoisted_4 = { class: "simple-message-body" }
const _hoisted_5 = { class: "timestamp" }

import { computed } from '../../../../../adapter-vue';
import TUIChatEngine from '@tencentcloud/chat-uikit-engine';
import Avatar from '../../../../common/Avatar/index.vue';
import { calculateTimestamp } from '../../../utils/utils';

interface IProps {
  sender: string;
  avatar: string;
  type: string;
  time: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'message-container',
  props: {
    sender: { default: '' },
    avatar: { default: '' },
    type: {},
    time: {}
  },
  setup(__props: any) {

const props = __props;

const TYPES = TUIChatEngine.TYPES;

const isNoPadding = computed(() => {
  return [TYPES.MSG_IMAGE, TYPES.MSG_VIDEO, TYPES.MSG_MERGER].includes(props.type);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Avatar, {
        url: props.avatar
      }, null, 8, ["url"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(props.sender), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass({
            'simple-message-content': true,
            'no-padding': _unref(isNoPadding)
          })
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(calculateTimestamp)(props.time*1000)), 1)
      ])
    ])
  ]))
}
}

})