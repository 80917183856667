import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "member"
}
const _hoisted_2 = { class: "list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "name" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  class: "edit-h5"
}
const _hoisted_8 = { class: "main" }
const _hoisted_9 = { class: "edit-h5-header" }
const _hoisted_10 = { class: "left" }
const _hoisted_11 = { class: "member" }
const _hoisted_12 = { class: "list list-uniapp" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "name" }
const _hoisted_16 = ["onClick"]

import TUIChatEngine, {
  TUITranslateService,
} from '@tencentcloud/chat-uikit-engine';
import { watchEffect, ref } from '../../../adapter-vue';
import Icon from '../../common/Icon.vue';
import delIcon from '../../../assets/icon/del-icon.svg';
import { IGroupSelfInfo, IGroupMember } from '../../../interface';
import { isUniFrameWork } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'manage-member',
  props: {
  list: {
    type: Array,
    default: () => [],
  },
  total: {
    type: Number,
    default: () => 0,
  },
  isShowDel: {
    type: Boolean,
    default: () => false,
  },
  self: {
    type: Object,
    default: () => ({}),
  },
},
  emits: ['more', 'del', 'handleMemberProfileShow', 'close'],
  setup(__props, { emit: __emit }) {

const props = __props;

const totalMember = ref(0);
const memberList = ref<Array<IGroupMember>>([]);
const isShowDeleteBtn = ref(false);
const selfValue = ref<IGroupSelfInfo>({});

watchEffect(() => {
  totalMember.value = props.total;
  isShowDeleteBtn.value = props.isShowDel;
  memberList.value = props.list as Array<IGroupMember>;
  selfValue.value = props.self;
});

const emits = __emit;

const handleRoleName = (item: any) => {
  let name = '';
  switch (item?.role) {
    case TUIChatEngine.TYPES.GRP_MBR_ROLE_ADMIN:
      name = TUITranslateService.t('TUIGroup.管理员');
      break;
    case TUIChatEngine.TYPES.GRP_MBR_ROLE_OWNER:
      name = TUITranslateService.t('TUIGroup.群主');
      break;
  }
  if (name) {
    name = `(${name})`;
  }
  if (item.userID === selfValue.value.userID) {
    name += ` (${TUITranslateService.t('TUIGroup.我')})`;
  }
  return name;
};

const getMore = () => {
  emits('more');
};

const submit = (item: any) => {
  emits('del', [item]);
};

const handleMemberProfileShow = (user: any) => {
  emits('handleMemberProfileShow', user);
};

const close = (tabName: string) => {
  emits('close', tabName);
};

return (_ctx: any,_cache: any) => {
  return (!_unref(isUniFrameWork))
    ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(memberList), (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "list-item"
            }, [
              _createElementVNode("aside", {
                class: "aside",
                onClick: ($event: any) => (handleMemberProfileShow(item))
              }, [
                _createElementVNode("img", {
                  class: "avatar",
                  src: 
              item.avatar ||
                'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
            ,
                  onerror: "this.onerror=null;this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
                }, null, 8, _hoisted_4),
                _createElementVNode("span", _hoisted_5, _toDisplayString(item.nick || item.userID), 1),
                _createElementVNode("span", null, _toDisplayString(handleRoleName(item)), 1)
              ], 8, _hoisted_3),
              _createElementVNode("div", {
                onClick: ($event: any) => (submit(item))
              }, [
                (item.role !== 'Owner' && _unref(isShowDeleteBtn))
                  ? (_openBlock(), _createBlock(Icon, {
                      key: 0,
                      file: _unref(delIcon),
                      width: '16px',
                      height: '16px'
                    }, null, 8, ["file"]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_6)
            ]))
          }), 128)),
          (_unref(memberList).length < _unref(totalMember))
            ? (_openBlock(), _createElementBlock("li", {
                key: 0,
                class: "list-item",
                onClick: getMore
              }, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.查看更多`)), 1))
            : _createCommentVNode("", true)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
        _createElementVNode("main", _hoisted_8, [
          _createElementVNode("header", _hoisted_9, [
            _createElementVNode("aside", _hoisted_10, [
              _createElementVNode("h1", null, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.群成员`)), 1)
            ]),
            _createElementVNode("span", {
              class: "close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (close('member')))
            }, _toDisplayString(_unref(TUITranslateService).t(`关闭`)), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("ul", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(memberList), (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "list-item"
                }, [
                  _createElementVNode("aside", {
                    class: "aside",
                    onClick: ($event: any) => (handleMemberProfileShow(item))
                  }, [
                    _createElementVNode("img", {
                      class: "avatar",
                      src: 
                  item.avatar ||
                    'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
                ,
                      onerror: "this.onerror=null;this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
                    }, null, 8, _hoisted_14),
                    _createElementVNode("span", _hoisted_15, _toDisplayString(item.nick || item.userID), 1),
                    _createElementVNode("span", null, _toDisplayString(handleRoleName(item)), 1)
                  ], 8, _hoisted_13),
                  _createElementVNode("div", {
                    onClick: ($event: any) => (submit(item))
                  }, [
                    (item.role !== 'Owner' && _unref(isShowDeleteBtn))
                      ? (_openBlock(), _createBlock(Icon, {
                          key: 0,
                          file: _unref(delIcon),
                          width: '16px',
                          height: '16px'
                        }, null, 8, ["file"]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_16)
                ]))
              }), 128)),
              (_unref(memberList).length < _unref(totalMember))
                ? (_openBlock(), _createElementBlock("li", {
                    key: 0,
                    class: "list-item",
                    onClick: getMore
                  }, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.查看更多`)), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]))
}
}

})