import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "el-icon-location-outline" }
const _hoisted_3 = ["src"]

import { watchEffect, ref } from '../../../../adapter-vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'message-location',
  props: {
  content: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props) {

const props = __props;
const data = ref();
watchEffect(() => {
  data.value = props.content;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    class: "message-location",
    href: _unref(data).href,
    target: "_blank",
    title: "点击查看详情"
  }, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(data).description), 1),
    _createElementVNode("img", {
      src: _unref(data).url
    }, null, 8, _hoisted_3)
  ], 8, _hoisted_1))
}
}

})