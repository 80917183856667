import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, isRef as _isRef, vModelText as _vModelText, withKeys as _withKeys, withModifiers as _withModifiers, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "tui-search-input-left" }
const _hoisted_2 = ["placeholder"]

import { ref, onMounted, onUnmounted } from '../../../adapter-vue';
import {
  TUIStore,
  StoreName,
  TUITranslateService,
} from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import Icon from '../../common/Icon.vue';
import searchIcon from '../../../assets/icon/search.svg';
import closeIcon from '../../../assets/icon/input-close.svg';
import { isPC } from '../../../utils/env';
import { ISearchInputValue, ISearchingStatus } from '../type';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  placeholder: {
    type: String,
    default: () => TUITranslateService.t('TUISearch.搜索'),
  },
  searchType: {
    type: String,
    default: 'global', // "global" / "conversation"
    validator(value: string) {
      return ['global', 'conversation'].includes(value);
    },
  },
},
  setup(__props) {

const props = __props;

const searchValueModel = ref<string>('');
const currentSearchInputValue = ref<string>('');
const searchingStatus = ref<boolean>(false);

function onCurrentSearchInputValueChange(obj: ISearchInputValue) {
  if (obj?.searchType === props?.searchType) {
    currentSearchInputValue.value = obj?.value;
    searchValueModel.value = obj?.value;
  }
}

function onCurrentSearchingStatusChange(obj: ISearchingStatus) {
  if (obj?.searchType === props?.searchType) {
    searchingStatus.value = obj?.isSearching;
  }
}

onMounted(() => {
  TUIStore.watch(StoreName.SEARCH, {
    currentSearchInputValue: onCurrentSearchInputValueChange,
    currentSearchingStatus: onCurrentSearchingStatusChange,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.SEARCH, {
    currentSearchInputValue: onCurrentSearchInputValueChange,
    currentSearchingStatus: onCurrentSearchingStatusChange,
  });
});

const search = () => {
  // Avoid duplicate searches
  if (searchValueModel.value === currentSearchInputValue.value) {
    return;
  }
  TUIStore.update(StoreName.SEARCH, 'currentSearchInputValue', {
    value: searchValueModel.value,
    searchType: props.searchType,
  });
};

const endSearching = () => {
  searchingStatus.value = false;
  TUIStore.update(StoreName.SEARCH, 'currentSearchingStatus', {
    isSearching: false,
    searchType: props.searchType,
  });
  TUIStore.update(StoreName.SEARCH, 'currentSearchInputValue', {
    value: '',
    searchType: props.searchType,
  });
};

const onSearchInputClick = () => {
  TUIStore.update(StoreName.SEARCH, 'currentSearchingStatus', {
    isSearching: true,
    searchType: props.searchType,
  });
};

const onBlur = () => {
  TUIGlobal?.hideKeyboard?.();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'tui-search-input-container',
      !_unref(isPC) && 'tui-search-input-container-h5',
      props.searchType === 'global'
        ? 'tui-search-input-container-global'
        : 'tui-search-input-container-conversation'
    ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['tui-search-input', !_unref(isPC) && 'tui-search-input-h5'])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(Icon, {
          class: "icon",
          file: _unref(searchIcon),
          width: "14px",
          height: "14px"
        }, null, 8, ["file"])
      ]),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(searchValueModel) ? (searchValueModel).value = $event : null)),
        class: "tui-search-input-main",
        type: "text",
        placeholder: props.placeholder,
        focus: false,
        enterkeyhint: "search",
        onBlur: onBlur,
        onKeyup: _withKeys(search, ["enter"]),
        onConfirm: search,
        onClick: _withModifiers(onSearchInputClick, ["stop","prevent"])
      }, null, 40, _hoisted_2), [
        [_vModelText, _unref(searchValueModel)]
      ]),
      (_unref(searchingStatus))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "tui-search-input-right",
            onClick: endSearching
          }, [
            _createVNode(Icon, {
              class: "icon",
              file: _unref(closeIcon),
              width: "14px",
              height: "14px"
            }, null, 8, ["file"])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (!_unref(isPC) && _unref(searchingStatus) && props.searchType === 'global')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([
        'tui-search-input-cancel',
        !_unref(isPC) && 'tui-search-input-h5-cancel',
      ]),
          onClick: endSearching
        }, _toDisplayString(_unref(TUITranslateService).t("TUISearch.取消")), 3))
      : _createCommentVNode("", true)
  ], 2))
}
}

})