import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-text" }
const _hoisted_3 = { class: "header-close-icon" }
const _hoisted_4 = { class: "read-status-counter-container" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "status-text" }
const _hoisted_7 = { class: "status-count" }
const _hoisted_8 = { class: "read-status-member-list" }
const _hoisted_9 = {
  key: 0,
  class: "empty-list-tip"
}
const _hoisted_10 = { class: "username" }
const _hoisted_11 = { class: "username" }
const _hoisted_12 = {
  key: 2,
  class: "fetch-more-container"
}

import { ref, onMounted, watch, nextTick } from '../../../../adapter-vue';

import { IMessageModel, TUIStore, TUIChatService, TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import closeIcon from '../../../../assets/icon/icon-close.svg';
import Icon from '../../../common/Icon.vue';
import Overlay from '../../../common/Overlay/index.vue';
import Avatar from '../../../common/Avatar/index.vue';
import FetchMore from '../../../common/FetchMore/index.vue';
import type { IGroupMessageReadMemberData, IMemberData, ITabInfo, TabName } from './interface';
import { isMobile } from '../../../../utils/env';

type ReadType = 'unread' | 'read' | 'all';

interface IProps {
  message: IMessageModel;
}

interface IEmits {
  (key: 'setReadReceiptPanelVisible', visible: boolean, message?: IMessageModel): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    message: { default: () => ({}) as IMessageModel }
  },
  emits: ["setReadReceiptPanelVisible"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;
const props = __props;

let lastUnreadCursor: string = '';
let lastReadCursor: string = '';
const tabNameList: TabName[] = ['unread', 'read'];
const isListFetchCompleted: Record<TabName, boolean> = {
  unread: false,
  read: false,
  close: false,
};

const isPullDownFetching = ref<boolean>(false);
const isPanelClose = ref<boolean>(false);
const isFirstLoadFinished = ref<boolean>(false);
const isStopFetchMore = ref<boolean>(false);
const currentTabName = ref<TabName>('unread');
const tabInfo = ref<ITabInfo>(generateInitalTabInfo());

onMounted(async () => {
  await initAndRefetchReceiptInfomation();
  nextTick(() => {
    isFirstLoadFinished.value = true;
  });
});

watch(
  () => props.message.readReceiptInfo.readCount,
  () => {
    initAndRefetchReceiptInfomation();
  },
);

async function fetchGroupMessageRecriptMemberListByType(readType: ReadType = 'all') {
  const message = TUIStore.getMessageModel(props.message.ID);

  let unreadResult = {} as IGroupMessageReadMemberData;
  let readResult = {} as IGroupMessageReadMemberData;

  if (readType === 'all' || readType === 'unread') {
    unreadResult = await TUIChatService.getGroupMessageReadMemberList({
      message,
      filter: 1,
      cursor: lastUnreadCursor,
      count: 100,
    });
    if (unreadResult) {
      lastUnreadCursor = unreadResult.data.cursor;
      if (unreadResult.data.isCompleted) {
        isListFetchCompleted.unread = true;
      }
    }
  }

  if (readType === 'all' || readType === 'read') {
    readResult = await TUIChatService.getGroupMessageReadMemberList({
      message,
      filter: 0,
      cursor: lastReadCursor,
      count: 100,
    });
    if (readResult) {
      lastReadCursor = readResult.data.cursor;
      if (readResult.data.isCompleted) {
        isListFetchCompleted.read = true;
      }
    }
  }

  // Fetch the total number of read and unread users
  const { unreadCount: totalUnreadCount, readCount: totalReadCount } = message.readReceiptInfo;

  return {
    unreadResult: {
      count: totalUnreadCount,
      ...unreadResult.data,
    },
    readResult: {
      count: totalReadCount,
      ...readResult.data,
    },
  };
}

async function pullDownFetchMoreData() {
  /**
   * Use isPullDownFetching to control the state of the FetchMore component
   * Also, implement locking for intersectionObserver under uniapp
   * Because there is no isIntersecting in uniapp, it is impossible to determine whether the observed element has entered or exited the observation area
  */
  if (isListFetchCompleted[currentTabName.value] || isPullDownFetching.value) {
    return;
  }
  isPullDownFetching.value = true;
  if (currentTabName.value === 'unread' || currentTabName.value === 'read') {
    const { unreadResult, readResult } = await fetchGroupMessageRecriptMemberListByType(currentTabName.value);
    checkStopFetchMore();
    try {
      tabInfo.value.unread.memberList = tabInfo.value.unread.memberList.concat(unreadResult.unreadUserInfoList || []);
      tabInfo.value.read.memberList = tabInfo.value.read.memberList.concat(readResult.readUserInfoList || []);
    } finally {
      isPullDownFetching.value = false;
    }
  }
}

/**
 * Initializes and refetches receipt information.
 *
 * @return {Promise<void>} A promise that resolves when the function has completed.
 */
async function initAndRefetchReceiptInfomation(): Promise<void> {
  lastUnreadCursor = '';
  lastReadCursor = '';
  isStopFetchMore.value = false;
  isListFetchCompleted.unread = false;
  isListFetchCompleted.read = false;
  const { unreadResult, readResult } = await fetchGroupMessageRecriptMemberListByType('all');
  checkStopFetchMore();
  resetTabInfo('read', readResult.count, readResult.readUserInfoList);
  resetTabInfo('unread', unreadResult.count, unreadResult.unreadUserInfoList);
  resetTabInfo('close');
}

/**
 * Checks if the fetch more operation should be stopped
 * by IntersetctionObserver.disconnect().
 *
 * @return {void}
 */
function checkStopFetchMore(): void {
  if (isListFetchCompleted.read && isListFetchCompleted.unread) {
    isStopFetchMore.value = true;
  }
}

/**
 * Resets the information of a specific tab.
 *
 * @param {TabName} tabName - The name of the tab to reset.
 * @param {number} [count] - The count to assign to the tab. Optional.
 * @param {IMemberData[]} [memberList] - The list of members to assign to the tab. Optional.
 * @return {void} - This function does not return anything.
 */
function resetTabInfo(tabName: TabName, count?: number, memberList?: IMemberData[]): void {
  tabInfo.value[tabName].count = count;
  tabInfo.value[tabName].memberList = memberList || [];
}

/**
 * Generates the initial tab information.
 *
 * @return {ITabInfo} The initial tab information.
 */
function generateInitalTabInfo(): ITabInfo {
  return {
    read: {
      tabName: TUITranslateService.t('TUIChat.已读'),
      count: undefined,
      memberList: [],
    },
    unread: {
      tabName: TUITranslateService.t('TUIChat.未读'),
      count: undefined,
      memberList: [],
    },
    close: {
      tabName: TUITranslateService.t('TUIChat.关闭'),
      count: undefined,
      memberList: [],
    },
  };
}

/**
 * Toggles the tab name.
 *
 * @param {TabName} tabName - The name of the tab to toggle.
 * @return {void} This function does not return anything.
 */
function toggleTabName(tabName: TabName): void {
  currentTabName.value = tabName;
}

function closeReadReceiptPanel(): void {
  isPanelClose.value = true;
  setTimeout(() => {
    emits('setReadReceiptPanelVisible', false);
  }, 200);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Overlay, {
    maskColor: 'transparent',
    onOnOverlayClick: closeReadReceiptPanel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass({
        'read-receipt-panel': true,
        'read-receipt-panel-mobile': _unref(isMobile),
        'read-receipt-panel-close-mobile': _unref(isMobile) && _unref(isPanelClose),
      })
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(TUITranslateService).t("TUIChat.消息详情")), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(Icon, {
              size: "12px",
              hotAreaSize: "8",
              file: _unref(closeIcon),
              onOnClick: closeReadReceiptPanel
            }, null, 8, ["file"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tabNameList, (tabName) => {
            return _createElementVNode("div", {
              key: tabName,
              class: _normalizeClass({
            'read-status-counter': true,
            'active': tabName === _unref(currentTabName),
          }),
              onClick: ($event: any) => (toggleTabName(tabName))
            }, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(tabInfo)[tabName].tabName), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(tabInfo)[tabName].count === undefined ? "" : _unref(tabInfo)[tabName].count), 1)
            ], 10, _hoisted_5)
          }), 64))
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_unref(tabInfo)[_unref(currentTabName)].count === 0 && _unref(isFirstLoadFinished))
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, " - " + _toDisplayString(_unref(TUITranslateService).t('TUIChat.空')) + " - ", 1))
            : (_unref(isFirstLoadFinished))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_unref(currentTabName) === 'unread')
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(tabInfo)[_unref(currentTabName)].memberList, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item.userID,
                          class: "read-status-member-container"
                        }, [
                          _createVNode(Avatar, {
                            class: "read-status-avatar",
                            useSkeletonAnimation: "",
                            url: item.avatar || ''
                          }, null, 8, ["url"]),
                          _createElementVNode("div", _hoisted_10, _toDisplayString(item.nick || item.userID), 1)
                        ]))
                      }), 128))
                    : _createCommentVNode("", true),
                  (_unref(currentTabName) === 'read')
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(tabInfo)[_unref(currentTabName)].memberList, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item.userID,
                          class: "read-status-member-container"
                        }, [
                          _createVNode(Avatar, {
                            class: "read-status-avatar",
                            useSkeletonAnimation: "",
                            url: item.avatar
                          }, null, 8, ["url"]),
                          _createElementVNode("div", _hoisted_11, _toDisplayString(item.nick || item.userID), 1)
                        ]))
                      }), 128))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
          (_unref(isFirstLoadFinished))
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(FetchMore, {
                  isFetching: _unref(isPullDownFetching),
                  isTerminateObserve: _unref(isStopFetchMore),
                  onOnExposed: pullDownFetchMoreData
                }, null, 8, ["isFetching", "isTerminateObserve"])
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ]),
    _: 1
  }))
}
}

})