import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import TUIChatEngine, {
  TUIChatService,
  TUIStore,
  StoreName,
  IConversationModel,
  SendMessageParams,
  SendMessageOptions,
} from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import { ref } from '../../../../adapter-vue';
import { isPC, isWeChat, isUniFrameWork } from '../../../../utils/env';
import ToolbarItemContainer from '../toolbar-item-container/index.vue';
import videoIconLight from '../../../../assets/icon/video-light.svg';
import videoIconDark from '../../../../assets/icon/video-dark.svg';
import videoUniIcon from '../../../../assets/icon/video-uni.png';
import cameraUniIcon from '../../../../assets/icon/camera-uni.png';
import { isEnabledMessageReadReceiptGlobal } from '../../utils/utils';
import OfflinePushInfoManager, { IOfflinePushInfoCreateParams } from '../../offlinePushInfoManager/index';
import TUIChatConfig from '../../config';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  // Video source, only valid for uni-app version, web version only supports selecting videos from files
  // album: Select from files
  // camera: Take a video using the camera
  videoSourceType: {
    type: String,
    default: 'album',
  },
},
  setup(__props) {

const props = __props;

const inputRef = ref();
const currentConversation = ref<IConversationModel>();

TUIStore.watch(StoreName.CONV, {
  currentConversation: (conversation: IConversationModel) => {
    currentConversation.value = conversation;
  },
});

const handleIcon = (): string => {
  if (isUniFrameWork) {
    switch (props.videoSourceType) {
      case 'album':
        return videoUniIcon;
      case 'camera':
        return cameraUniIcon;
      default:
        return videoUniIcon;
    }
  } else {
    const videoIcon = TUIChatConfig.getTheme() === 'dark' ? videoIconDark : videoIconLight;
    return videoIcon;
  }
};

const handleTitle = (): string => {
  if (isUniFrameWork && props.videoSourceType === 'camera') {
    return '录制';
  } else {
    return '视频';
  }
};

const onIconClick = () => {
  // uni-app send video
  if (isUniFrameWork) {
    if (isWeChat && TUIGlobal?.chooseMedia) {
      TUIGlobal?.chooseMedia({
        mediaType: ['video'],
        count: 1,
        sourceType: [props.videoSourceType],
        maxDuration: 60,
        success: function (res: any) {
          sendVideoMessage(res);
        },
      });
    } else {
      TUIGlobal?.chooseVideo({
        count: 1,
        sourceType: [props.videoSourceType],
        compressed: false,
        success: function (res: any) {
          sendVideoMessage(res);
        },
      });
    }
  } else {
    inputRef?.value?.click && inputRef?.value?.click();
  }
};

const sendVideoInWeb = (e: any) => {
  if (e?.target?.files?.length <= 0) {
    return;
  }
  sendVideoMessage(e?.target);
  e.target.value = '';
};

const sendVideoMessage = (file: any) => {
  if (!file) {
    return;
  }
  const options = {
    to:
      currentConversation?.value?.groupProfile?.groupID
      || currentConversation?.value?.userProfile?.userID,
    conversationType: currentConversation?.value?.type,
    payload: {
      file,
    },
    needReadReceipt: isEnabledMessageReadReceiptGlobal(),
  } as SendMessageParams;
  const offlinePushInfoCreateParams: IOfflinePushInfoCreateParams = {
    conversation: currentConversation.value,
    payload: options.payload,
    messageType: TUIChatEngine.TYPES.MSG_VIDEO,
  };
  const sendMessageOptions: SendMessageOptions = {
    offlinePushInfo: OfflinePushInfoManager.create(offlinePushInfoCreateParams),
  };
  TUIChatService.sendVideoMessage(options, sendMessageOptions);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ToolbarItemContainer, {
    iconFile: handleIcon(),
    title: handleTitle(),
    needDialog: false,
    iconWidth: _unref(isUniFrameWork) ? '32px' : '20px',
    iconHeight: _unref(isUniFrameWork)
      ? props.videoSourceType === 'album'
        ? '20px'
        : '25px'
      : '18px'
    ,
    onOnIconClick: onIconClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['video-upload', !_unref(isPC) && 'video-upload-h5'])
      }, [
        _createElementVNode("input", {
          ref_key: "inputRef",
          ref: inputRef,
          title: "视频",
          type: "file",
          "data-type": "video",
          accept: "video/*",
          onChange: sendVideoInWeb
        }, null, 544)
      ], 2)
    ]),
    _: 1
  }, 8, ["iconFile", "title", "iconWidth", "iconHeight"]))
}
}

})