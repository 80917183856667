import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, watchEffect } from '../../../adapter-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  open: {
    type: Boolean,
    default: false,
  },
},
  emits: ['change'],
  setup(__props, { emit: __emit }) {

const props = __props;

const isSliderOpen = ref(false);

const emits = __emit;

watchEffect(() => {
  isSliderOpen.value = props.open;
});

const toggleSlider = () => {
  isSliderOpen.value = !isSliderOpen.value;
  emits('change', isSliderOpen.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slider-box", [_unref(isSliderOpen) && 'slider-open']]),
    onClick: toggleSlider
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("span", { class: "slider-block" }, null, -1)
  ]), 2))
}
}

})