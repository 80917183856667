const message = {
  "tip": {
    "群成员": "group members",
    "加入群组": "joined the group",
    "退出群组": "left the group",
    "被": "by",
    "踢出群组": "was kicked out of the group",
    "成为管理员": "was set as an admin",
    "被撤销管理员": "was revoked",
    "被禁言": " was unmuted",
    "被取消禁言": "cancel the prohibition",
    "群提示消息": "group prompt message",
    "管理员": "The admin",
    "开启全员禁言": "enabled Mute All",
    "取消全员禁言": "disabled Mute All",
    "成为新的群主": "became the new group owner",
    "修改群名为": "changed the group name to",
    "发布新公告": "posted a new notice",
    "把群公告修改为": "changed the group notice to",
    "申请加入群组": "requested to join the group",
    "成功加入群组": "Successfully joined the group",
    "被拒绝": "The request to join the group was refused",
    "你被管理员": "You",
    "群": "group",
    "解散": "disbanded",
    "创建群": "created the group",
    "邀请你加群": "invite you to join the group",
    "你退出群组" : "You left the group",
    "你被": "You were",
    "设置为群": "set as group",
    "的管理员": "as an admin",
    "撤销群": "revoked",
    "的管理员身份": "admin role of the group",
    "同意加群": "agreed to join the group",
    "拒接加群": "refused to join the group",
    "自定义群系统通知": "Custom group system notification"
  },
  "custom": {
    "自定义消息": "Custom message",
    "通话时长": "Call duration",
    "通话结束": "Call End",
    "切换语音通话": "Switch voice call",
    "切换视频通话": "Switch video call",
    "发起通话": "Start a call",
    "取消通话": "Cancel",
    "已接听": "Answered",
    "拒绝通话": "Decline",
    "无应答": "No answer",
    "对本次服务评价": "Rate this service",
    "查看详情>>": "View details >>"
  }
}

export default message;
