import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { isUniFrameWork } from '../../../utils/env';

interface IProps {
  message: IMessageModel;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'message-room-default',
  props: {
    message: { default: () => ({} as IMessageModel) }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'room-default',
      _unref(isUniFrameWork) && 'room-default-uni',
      props.message.flow === 'in' ? 'room-default-in' : 'room-default-out',
    ])
  }, _toDisplayString(_unref(TUITranslateService).t("message.custom.自定义消息")), 3))
}
}

})