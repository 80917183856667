import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['message-abstract-video'])
}
const _hoisted_2 = { class: "message-abstract-video-box" }
const _hoisted_3 = ["src"]

import { computed } from '../../../../../adapter-vue';
import Icon from '../../../../common/Icon.vue';
import playIcon from '../../../../../assets/icon/video-play.png';
import { IVideoMessageContent } from '../../../../../interface';
interface IProps {
  messageContent: Record<string, unknown> | IVideoMessageContent | undefined;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'message-abstract-video',
  props: {
    messageContent: { default: () => ({}) as IVideoMessageContent }
  },
  setup(__props: any) {

const props = __props;
const videoUrl = computed<string>(() => {
  return (props.messageContent as IVideoMessageContent).snapshotUrl || (props.messageContent as IVideoMessageContent).url;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _unref(videoUrl),
        class: _normalizeClass(['video-snapshot'])
      }, null, 8, _hoisted_3),
      _createVNode(Icon, {
        file: _unref(playIcon),
        class: "video-play"
      }, null, 8, ["file"])
    ])
  ]))
}
}

})